import { CardContent, Grid } from '@mui/material'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IUserManualPage } from 'types/userManualInterfaces'
import { chooseDBTranslation } from 'utils/translations'

interface HelpModalProps {
  article: IUserManualPage
}

/**
 * Help article modal.
 *
 * @returns Modal component for showing help article.
 * @notExported
 */
const HelpModal: React.FC<HelpModalProps> = ({ article }) => {
  const { i18n } = useTranslation()

  return (
    <CardContent sx={{ backgroundColor: '#fff' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div dangerouslySetInnerHTML={{ __html: chooseDBTranslation(i18n, article).content }} />
        </Grid>
      </Grid>
    </CardContent>
  )
}

export default withSimpleModal(HelpModal)
