import { DialogContent, Grid } from '@mui/material'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageDropzone } from '../Dropzone/ImageDropzone'

/** @notExported */
interface ImageModalProps {
  /** The function to close the modal with the selected image */
  onClose: (image?: string) => void
}

/**
 * Image picker modal component for Slate editor.
 *
 * @returns Image picker modal component.
 * @notExported
 */
const ImageModal: React.FC<ImageModalProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const [image, setImage] = useState<string>()

  useEffect(() => {
    if (image && image) {
      onClose(image)
    }
  }, [image])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setImage(reader.result)
      }
    }
    reader.readAsDataURL(acceptedFiles[0])
  }, [])

  return (
    <DialogContent>
      <Grid>
        <ImageDropzone
          onDrop={onDrop}
          accept={{ 'image/jpg': ['.jpg'], 'image/png': ['.png'] }}
          multiple={false}
          oldImage={''}
          dragHint={t('dropbox.dragImage')}
          dropHint={t('dropbox.dropImage')}
        />
      </Grid>
    </DialogContent>
  )
}

export default withSimpleModal(ImageModal)
