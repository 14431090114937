/**
 * Object for application colors
 */
export default Object.freeze({
  primary: '#193F57',
  secondary: '#20add0',
  description: '#4F5560',
  background: '#F8F8F8',
  secondaryBackground: '#FFFFFF',
  blueHoverHalo: '#E8F7FA',
  secondaryHoverHalo: '#1D9EC0',
  red: '#BE3C3C',
  redButton: '#AB393B',
  redButtonHoverHalo: '#F9EBEB',
  orange: '#E6A219',
  orangeHover: '#CF951B',
  orangeButtonHoverHalo: '#E6A2191A',
  green: '#46BE3C',
  greenButtonHover: '#3FAE3B',
  greenButtonHoverHalo: '#46BE3C1A',
  gray: '#BFBFBF',
  borders: '#E0E0E0',
  black: '#000000',
  hoverHalo: '#FFFFFF1A',
  purple: '#914EAA',
  certificatesBackground: '#F3ECF6',
  greenHoverHalo: '#ECF9EB',
  coursesBackground: '#FDF6E7',
  tableRowBackground: '#F5F5F5',
  codeBackground: '#403b37',
  codeText: '#FFFFFF',

  chart1: '#0e2e4a',
  chart2: '#5c4277',
  chart3: '#b74a7c',
  chart4: '#f56b58',
  chart5: '#fab01b',

  // New search colors
  searchBackground: '#FFFFFF',
  chipBackground: '#5762741A',
  fontNormalColor: '#576274',
  borderColor: '#c0c0c0',
  newPrimary: '#0D6AFF',
})
