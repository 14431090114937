import {
  IEmployee,
  IGroup,
  IGroupMember,
  IFeature,
  IActiveStatistic,
  IStatisticAccount,
  IGroupQuery,
  SkillExperienceRow,
  OrganizationAllocationData,
} from 'types/adminInterfaces'
import { AccountId } from 'types/ids'
import { IAccount, IAccountQuery, IOrganization, ISite } from 'types/userInterfaces'
import { SubAPI } from './API'
import { ICv } from 'types/cvsInterfaces'
import { SortingState } from '@tanstack/react-table'

type AdminSubObject = {
  id: number
  error?: string
}

/**
 * Base class for all admin sub APIs.
 *
 * @notExported
 */
class AdminSubAPI<T extends AdminSubObject> extends SubAPI {
  urlPart: string

  constructor(urlPart: string) {
    super()

    this.urlPart = urlPart
  }

  /**
   * Creates a new object in the database.
   *
   * @param data
   * @returns Created object
   * @notExported
   */
  public create(data: unknown, controller?: AbortController): Promise<T> {
    return this.api.post(`${this.urlPart}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Updates an object in the database.
   *
   * @param id - Object ID
   * @param data
   * @returns Updated object
   * @notExported
   */
  public save(id: number, data: unknown, controller?: AbortController): Promise<T> {
    return this.api.put(`${this.urlPart}/${id}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Deletes an object from the database.
   *
   * @param id - Object ID
   * @returns Promise of deletion
   * @notExported
   */
  public delete(id: number): Promise<T> {
    return this.api.delete(`${this.urlPart}/${id}`)
  }

  /**
   * Saves data as network manager
   *
   * @param id - Object ID
   * @param data
   * @returns Updated object
   * @notExported
   */
  public networkSave(id: number, data: unknown, controller?: AbortController): Promise<T> {
    return this.api.put(`${this.urlPart}/network/${id}`, data, controller ? { signal: controller.signal } : undefined)
  }
}

/**
 * AdminAPI class
 * @notExported
 */
class AdminAPI extends SubAPI {
  /**
   * Gets all employees of an organization.
   *
   * @param id - Organization ID
   * @returns Array of employees
   * @notExported
   */
  public getOrgEmployees(id: number, controller?: AbortController): Promise<IEmployee[]> {
    return this.api.get(`users/organization/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets all employees
   *
   * @returns Array of employees
   * @notExported
   */
  public getAllEmployees(): Promise<IEmployee[]> {
    return this.api.get(`users/all`)
  }

  /**
   * Gets an employee
   *
   * @param id ID of Employee
   * @returns Employee
   * @notExported
   */
  public getEmployee(id: number, controller?: AbortController): Promise<IEmployee> {
    return this.api.get(`users/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets all activated employees
   *
   * @returns Array of employees
   * @notExported
   */
  public getAllActivated(controller?: AbortController): Promise<IEmployee[]> {
    return this.api.get(`users/activated`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets all freelancers
   *
   * @returns Array of freelancer employees
   * @notExported
   */
  public getFreelancers(controller?: AbortController): Promise<IEmployee[]> {
    return this.api.get(`users/freelancers`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets all recruits
   *
   * @returns Array of recruit employees
   * @notExported
   */
  public getRecruits(controller?: AbortController): Promise<IEmployee[]> {
    return this.api.get('users/recruits', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets all CVs of an organization with pagination
   *
   * @param id - Organization ID
   * @param page - Page number
   * @returns Array of CVs on page
   * @notExported
   */
  public getAllOrganizationCvsPaginated(id: number, page: number): Promise<ICv[]> {
    return this.api.post(`cvs/organization/${id}/paginate`, { page })
  }

  /**
   * Gets count of all CVs of an organization
   *
   * @param id - Organization ID
   * @returns Count of CVs
   * @notExported
   */
  public getAllOrganizationCvsCount(id: number): Promise<number> {
    return this.api.get(`cvs/organization/${id}`)
  }

  /**
   * Gets all groups with pagination
   *
   * @param limit - How many per page
   * @param page - Page number
   * @param sorting - How should the elements be sorted
   * @param filter - Search parameter
   * @returns Group query results
   * @notExported
   */
  public getAllGroups(
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controller?: AbortController
  ): Promise<IGroupQuery> {
    return this.api.get(`groups/`, controller ? { signal: controller.signal } : undefined, {
      limit,
      page,
      sorting,
      filter: filter ? encodeURI(filter) : undefined,
    })
  }

  /**
   * Gets all users with pagination
   *
   * @param limit - How many per page
   * @param page - Page number
   * @param sorting - How should the elements be sorted
   * @param filter - Search parameter
   * @returns Account query results
   * @notExported
   */
  public getAllUsersPermissions(
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controller?: AbortController
  ): Promise<IAccountQuery> {
    return this.api.get(`groups/accounts/`, controller ? { signal: controller.signal } : undefined, {
      limit,
      page,
      sorting,
      filter: filter ? encodeURI(filter) : undefined,
    })
  }

  /**
   * Get user with groups
   *
   * @param id - User ID
   * @returns User with groups
   * @notExported
   */
  public getUserPermissions(id: number, controller?: AbortController): Promise<IAccount> {
    return this.api.get(`groups/account/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Add user to group
   *
   * @param accountId - User account ID
   * @param groupId - Group ID
   * @returns Group member
   * @notExported
   */
  public saveUserGroup(accountId: number, groupId: number): Promise<IGroupMember> {
    return this.api.post(`groups/account/`, { groupId, accountId })
  }

  /**
   * Remove user from group
   *
   * @param accountId - User account ID
   * @param groupId - Group ID
   * @returns Promise of deletion
   * @notExported
   */
  public removeUserGroup(accountId: number, groupId: number): Promise<void> {
    return this.api.put(`groups/account/`, { groupId, accountId })
  }

  /**
   * Gets a group by ID
   *
   * @param id - Group ID
   * @returns Group data
   * @notExported
   */
  public getGroup(id: number, controller?: AbortController): Promise<IGroup> {
    return this.api.get(`groups/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Changes group members
   *
   * @param groupId - Group ID
   * @param members - New members for the group
   * @returns Updated group members data
   * @notExported
   */
  public changeGroupMembers(groupId: number, members: IEmployee[]): Promise<IGroupMember[]> {
    return this.api.put(`groups/members/${groupId}`, { members })
  }

  /**
   * Adds a member to a group
   *
   * @param groupId - Group ID
   * @param accountId - Account ID
   * @returns Updated group data
   * @notExported
   */
  public addGroupMember(groupId: number, accountId: AccountId): Promise<IGroup> {
    return this.api.post(`groups/members/${groupId}`, { accountId })
  }

  /**
   * Removes a member from a group
   *
   * @param id - Member ID
   * @returns Deletion message
   * @notExported
   */
  public removeGroupMember(id: number): Promise<string> {
    return this.api.delete(`groups/member/${id}`)
  }

  /**
   * Request a password reset for the logged in user
   *
   * @param id - Account ID
   * @returns Success message
   * @notExported
   */
  public resetPassword(id: number): Promise<string> {
    return this.api.get(`account/password/${id}`)
  }

  /**
   * Save logo for organization
   *
   * @param data - Image data as FormData object
   * @param organizationId - Organization ID
   * @returns Promise of update
   * @notExported
   */
  public saveLogo(data: FormData, organizationId: number, controller?: AbortController): Promise<void> {
    return this.api.post(
      `files/organization/${organizationId}`,
      data,
      controller ? { signal: controller.signal } : undefined,
      {
        'Content-Type': 'multipart/form-data',
      }
    )
  }

  /**
   * Creates a new recruit or freelancer account
   *
   * @param data - Account data
   * @returns Created account
   * @notExported
   */
  public createRecruitFreelancer(data: unknown, controller?: AbortController): Promise<IEmployee> {
    return this.api.post(`users/recruitfreelancer`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Registers a freelancer account
   *
   * @param data - Account data
   * @returns Created account
   * @notExported
   */
  public registerFreelancer(data: unknown): Promise<IEmployee> {
    return this.api.post(`users/register`, data)
  }

  /**
   * Sends a reminder email to the user to update their CV
   *
   * @param id - Account ID
   * @returns Success message
   * @notExported
   */
  public remindCvUpdate(id: number): Promise<string> {
    return this.api.get(`users/remind/${id}`)
  }

  /**
   * Update enabled features for organization
   *
   * @param organizationId - Organization ID
   * @param features - Array of enabled features
   * @returns Updated features array
   * @notExported
   */
  public saveFeatures(organizationId: number, features: IFeature[], controller?: AbortController): Promise<IFeature[]> {
    return this.api.post(
      `feature-toggles/${organizationId}`,
      { features },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get enabled features for users organization
   *
   * @returns Array of enabled features for users organization
   * @notExported
   */
  public getUserFeatures(controller?: AbortController): Promise<string[]> {
    return this.api.get('feature-toggles/', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get emaployees as a company admin
   *
   * @param organizationIds - Array of organization IDs
   * @returns Array of employees
   * @notExported
   */
  public getCompanyAdminEmployees(organizationIds: number[]): Promise<IEmployee[]> {
    return this.api.post('organizations/employees', { ids: organizationIds })
  }

  /**
   * Get groups for organization
   *
   * @param organizationId - Organization ID
   * @returns Array of groups for the organization
   * @notExported
   */
  public getOrganizationGroups(organizationId: number, controller?: AbortController): Promise<IGroup[]> {
    return this.api.get(
      `organizations/${organizationId}/groups`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get Excel export of organization allocations
   *
   * @param organizationId - Organization ID
   * @param language - Language for export
   * @returns Excel export of organization allocations as a Blob object.
   * @notExported
   */
  public getExcelAllocations(organizationId: number, language: string): Promise<OrganizationAllocationData[]> {
    return this.api.get(`organizations/export/allocations/${organizationId}/${language}`, undefined)
  }

  /**
   * Get organization default language
   *
   * @param organizationId - Organization ID
   * @returns Default language for the organization
   * @notExported
   */
  public getOrganizationDefaultLanguage(organizationId: number, controller?: AbortController): Promise<string> {
    return this.api.get(
      `organizations/defaultLanguage/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get login report
   *
   * @returns Login report for the last 30 days
   * @notExported
   */
  public getActiveStatistics(controller?: AbortController): Promise<IActiveStatistic[]> {
    return this.api.get(`reports/login`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get login activity for all users
   *
   * @returns Array of login activity
   * @notExported
   */
  public getActiveUsers(controller?: AbortController): Promise<IStatisticAccount[]> {
    return this.api.get(`reports/login/current`, controller ? { signal: controller.signal } : undefined)
  }

  public getSkillsExperience(organizationId: number, controller?: AbortController): Promise<SkillExperienceRow[]> {
    return this.api.get(
      `organizations/export/skillsExperience/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  public disableEmployee(id: number): Promise<void> {
    return this.api.put(`users/disable/${id}`, { id })
  }
}

export const adminAPI = new AdminAPI()

export const adminEmployeeAPI = new AdminSubAPI<IAccount>('users')
export const adminOrganizationAPI = new AdminSubAPI<IOrganization>('organizations')
export const adminGroupAPI = new AdminSubAPI<IGroup>('groups')
export const adminOrganizationSiteAPI = new AdminSubAPI<ISite>('organizations/site')
