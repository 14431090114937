import React, { MouseEvent, useMemo, PropsWithChildren, Ref } from 'react'
import { Descendant, Editor, Element as SlateElement, Transforms, Range, Text } from 'slate'
import {
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react'
import {
  LinkElement,
  CustomEditor,
  ImageElement,
  ParagraphElement,
  RenderElementPropsFor,
  CustomElement,
  CustomElementWithAlign,
  CustomTextKey,
  BaseProps,
  AlignType,
  ListType,
  CustomElementFormat,
  MarkButtonProps,
  BlockButtonProps,
} from './custom-types.d'
import escapeHtml from 'escape-html'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Code,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatUnderlined,
  LooksOne,
  LooksTwo,
  Image as ImageIcon,
  Link,
  LinkOff,
} from '@mui/icons-material'
import { Grid, IconButton, Tooltip } from '@mui/material'
import colors from 'constants/colors'
import { jsx } from 'slate-hyperscript'
import { t } from 'i18next'

const LIST_TYPES = ['numbered-list', 'bulleted-list'] as const
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'] as const

export const serialize = (node: Descendant): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) {
      string = `<strong style="margin: 0">${string}</strong>`
    }
    if (node.italic) {
      string = `<i style="margin: 0">${string}</i>`
    }
    if (node.underline) {
      string = `<u style="margin: 0">${string}</u>`
    }
    if (node.code) {
      string = `<code style="margin: 0; background-color: ${colors.codeBackground}; color: ${colors.codeText}">${string}</code>`
    }
    return string
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'block-quote':
      return `<blockquote style="text-align: ${node.align ? node.align : 'left'}">${children}</blockquote>`
    case 'bulleted-list':
      return `<ul style="margin: 0">${serializeListChildren(node.children)}</ul>`
    case 'numbered-list':
      return `<ol style="margin: 0">${serializeListChildren(node.children)}</ol>`
    case 'paragraph':
      return `<p style="margin: 0; text-align: ${node.align ? node.align : 'left'}">${children}</p>`
    case 'heading-one':
      return `<h1 style="text-align: ${node.align ? node.align : 'left'}">${children}</h1>`
    case 'heading-two':
      return `<h2 style="text-align: ${node.align ? node.align : 'left'}">${children}</h2>`
    case 'code-block':
      return `<code style="margin: 0; background-color: ${colors.codeBackground}; color: ${
        colors.codeText
      }; text-align: ${node.align ? node.align : 'left'}">${children}</code>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}" style="margin: 0; text-align: ${
        node.align ? node.align : 'left'
      }">${children}</a>`
    case 'image':
      return `<img src="${node.url}" style="display: block; max-width: 100%; max-height: 100%; text-align: ${
        node.align ? node.align : 'left'
      }">${children}</img>`
    case 'list-item':
      return `<li style="margin:0; text-align: ${node.align ? node.align : 'left'}">${children}</li>`
    default:
      return children
  }
}

const serializeListChildren = (children): string => {
  let data = ''
  for (const child of children) {
    data += serialize(child)
  }
  return data
}

export const deserialize = (el: HTMLElement, markAttributes: { [key: string]: boolean | string } = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  let align: string | undefined
  switch (el.style.textAlign) {
    case 'left':
      align = 'left'
      nodeAttributes.align = 'left'
      break
    case 'center':
      align = 'center'
      nodeAttributes.align = 'center'
      break
    case 'right':
      align = 'right'
      nodeAttributes.align = 'right'
      break
    case 'justify':
      align = 'justify'
      nodeAttributes.align = 'justify'
      break
    default:
      align = undefined
      break
  }

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true
      break
    case 'I':
      nodeAttributes.italic = true
      break
    case 'U':
      nodeAttributes.underline = true
      break
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node as HTMLElement, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote', align }, children)
    case 'P':
      return jsx('element', { type: 'paragraph', align }, children)
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href'), align }, children)
    case 'IMG':
      return jsx('element', { type: 'image', url: el.getAttribute('src'), align }, children)
    case 'H1':
      return jsx('element', { type: 'heading-one', align }, children)
    case 'H2':
      return jsx('element', { type: 'heading-two', align }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list', align }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list', align }, children)
    case 'CODE':
      return jsx('element', { type: 'code-block', align }, children)
    case 'LI':
      return jsx('element', { type: 'list-item', align }, children)
    default:
      return children
  }
}

export const Toolbar = React.forwardRef(({ ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
  <Grid item ref={ref} sx={{ border: '1px solid #ccc', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
    {props.children}
  </Grid>
))

export const withInlines = (editor: CustomEditor) => {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } = editor

  editor.isInline = (element: CustomElement) => ['link', 'badge'].includes(element.type) || isInline(element)

  editor.isElementReadOnly = (element: CustomElement) => element.type === 'badge' || isElementReadOnly(element)

  editor.isSelectable = (element: CustomElement) => element.type !== 'badge' && isSelectable(element)

  editor.insertText = text => {
    if (
      text &&
      (text.includes('http') || text.includes('https') || text.includes('mailto:') || text.includes('tel:')) // TODO: add check for url
    ) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (
      text &&
      (text.includes('http') || text.includes('https') || text.includes('mailto:') || text.includes('tel:')) // TODO: add check for url
    ) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:']

const LinkComponent: React.FC<RenderElementPropsFor<LinkElement>> = ({ attributes, children, element }) => {
  const selected = useSelected()
  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null
    try {
      parsedUrl = new URL(element.url)
      // eslint-disable-next-line no-empty
    } catch {}
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href
    }
    return 'about:blank'
  }, [element.url])

  return (
    <a
      {...attributes}
      href={safeUrl}
      style={
        selected
          ? {
              boxShadow: '0 0 0 3px #ddd',
            }
          : {}
      }
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

export const RemoveLinkButton = () => {
  const editor = useSlate()

  return (
    <Tooltip title={t('richTextEditor.removeLink')}>
      <IconButton
        color={isLinkActive(editor) ? 'primary' : 'neutral'}
        onMouseDown={(_event: MouseEvent) => {
          if (isLinkActive(editor)) {
            unwrapLink(editor)
          }
        }}
      >
        {getButtonIcon('link_off')}
      </IconButton>
    </Tooltip>
  )
}

export const toggleBlock = (editor: CustomEditor, format: CustomElementFormat) => {
  const isActive = isBlockActive(editor, format, isAlignType(format) ? 'align' : 'type')
  const isList = isListType(format)

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isListType(n.type) && !isAlignType(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (isAlignType(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const toggleMark = (editor: CustomEditor, format: CustomTextKey) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style: React.CSSProperties = { marginTop: 0 }
  if (isAlignElement(element)) {
    style.textAlign = element.align as AlignType
  }
  if (!element.type.includes('heading')) {
    style.margin = 0
  }
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'image':
      return (
        <Image attributes={attributes} element={element}>
          {children}
        </Image>
      )
    case 'link':
      return (
        <LinkComponent attributes={attributes} element={element}>
          {children}
        </LinkComponent>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong style={{ margin: 0 }}>{children}</strong>
  }

  if (leaf.code) {
    children = (
      <code style={{ margin: 0, backgroundColor: colors.codeBackground, color: colors.codeText }}>{children}</code>
    )
  }

  if (leaf.italic) {
    children = <em style={{ margin: 0 }}>{children}</em>
  }

  if (leaf.underline) {
    children = <u style={{ margin: 0 }}>{children}</u>
  }

  return (
    <span style={{ margin: 0 }} {...attributes}>
      {children}
    </span>
  )
}

export const getButtonIcon = (icon: string) => {
  switch (icon) {
    case 'format_bold':
      return <FormatBold />

    case 'format_italic':
      return <FormatItalic />

    case 'format_underlined':
      return <FormatUnderlined />

    case 'code':
      return <Code />

    case 'looks_one':
      return <LooksOne />

    case 'looks_two':
      return <LooksTwo />

    case 'format_quote':
      return <FormatQuote />

    case 'format_list_numbered':
      return <FormatListNumbered />

    case 'format_list_bulleted':
      return <FormatListBulleted />

    case 'format_align_left':
      return <FormatAlignLeft />

    case 'format_align_center':
      return <FormatAlignCenter />

    case 'format_align_right':
      return <FormatAlignRight />

    case 'format_align_justify':
      return <FormatAlignJustify />

    case 'image':
      return <ImageIcon />

    case 'link':
      return <Link />

    case 'link_off':
      return <LinkOff />

    default:
      break
  }
}

export const BlockButton = ({ format, icon }: BlockButtonProps) => {
  const editor = useSlate()

  return (
    <Tooltip title={t(`richTextEditor.${format}`)}>
      <IconButton
        color={isBlockActive(editor, format, isAlignType(format) ? 'align' : 'type') ? 'neutral' : 'primary'}
        onMouseDown={(event: MouseEvent<HTMLSpanElement>) => {
          event.preventDefault()
          toggleBlock(editor, format)
        }}
      >
        {getButtonIcon(icon)}
      </IconButton>
    </Tooltip>
  )
}

export const MarkButton = ({ format, icon }: MarkButtonProps) => {
  const editor = useSlate()

  return (
    <Tooltip title={t(`richTextEditor.${format}`)}>
      <IconButton
        color={isMarkActive(editor, format) ? 'neutral' : 'primary'}
        onMouseDown={(event: MouseEvent<HTMLSpanElement>) => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
      >
        {getButtonIcon(icon)}
      </IconButton>
    </Tooltip>
  )
}

export const withImages = (editor: CustomEditor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      Array.from(files).forEach(file => {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url as string)
          })

          reader.readAsDataURL(file)
        }
      })
    } else if (text.includes('base64')) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertImage = (editor: CustomEditor, url: string) => {
  const text = { text: '' }
  const image: ImageElement = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
  const paragraph: ParagraphElement = {
    type: 'paragraph',
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, paragraph)
}

const Image: React.FC<RenderElementPropsFor<ImageElement>> = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)
  const selected = useSelected()
  const focused = useFocused()

  return (
    <Grid item {...attributes}>
      {children}
      <Grid
        item
        contentEditable={false}
        style={{
          position: 'relative',
        }}
      >
        <img
          src={element.url}
          style={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100vh',
            boxShadow: selected && focused ? `0 0 0 3px ${colors.borders}` : 'none',
          }}
        />
        <Tooltip title={t('richTextEditor.removeImage')}>
          <IconButton
            onClick={() => Transforms.removeNodes(editor, { at: path })}
            sx={{
              display: selected ? 'block' : 'none',
              position: 'absolute',
              bottom: '0.5em',
              left: '0.5em',
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const isBlockActive = (editor: CustomEditor, format: CustomElementFormat, blockType: 'type' | 'align' = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => {
        if (!Editor.isEditor(n) && SlateElement.isElement(n)) {
          if (blockType === 'align' && isAlignElement(n)) {
            return n.align === format
          }
          return n.type === format
        }
        return false
      },
    })
  )

  return !!match
}

const isMarkActive = (editor: CustomEditor, format: CustomTextKey) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isAlignType = (format: CustomElementFormat): format is AlignType => {
  return TEXT_ALIGN_TYPES.includes(format as AlignType)
}

const isListType = (format: CustomElementFormat): format is ListType => {
  return LIST_TYPES.includes(format as ListType)
}

const isAlignElement = (element: CustomElement): element is CustomElementWithAlign => {
  return 'align' in element
}

export const isLinkActive = (editor: CustomEditor): boolean => {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}
