import { Chip, Grid, Link } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { ISearchCertificate, ISearchCourse, ISearchDegree, ISearchLanguage, ISearchSkill } from 'types/searchInterfaces'
import { useTranslation } from 'react-i18next'
import colors from 'constants/colors'
import EditPopover from './SearchComponents/EditPopover'
import LanguageEditPopover from './SearchComponents/LanguageEditPopover'

/**
 * React functional component for displaying terms.
 *
 * @return {JSX.Element} The grid layout for displaying terms.
 */
const TermsDisplay: React.FC = (): JSX.Element => {
  const { searchData, setSearchData, resetSearch } = useSearchData()
  const { t } = useTranslation()

  const [anchorElement, setAnchorElement] = useState<HTMLElement>()
  const [itemData, setItemData] = useState<ISearchSkill>()
  const [updatedItemData, setUpdatedItemData] = useState<ISearchSkill>()
  const [languageItem, setLanguageItem] = useState<ISearchLanguage>()
  const [updatedLanguageItem, setUpdatedLanguageItem] = useState<ISearchLanguage>()

  /**
   * Handles the click event and updates the anchor element, item, and updated item.
   *
   * @param {Event} event - The click event
   * @param {any} data - The data associated with the event
   */
  const handleClick = (event, data: ISearchSkill) => {
    setAnchorElement(event.currentTarget)
    setItemData(data)
    setUpdatedItemData(data)
  }

  const handleLanguageClick = (event, data: ISearchLanguage) => {
    setAnchorElement(event.currentTarget)
    setLanguageItem(data)
    setUpdatedLanguageItem(data)
  }

  /**
   * Removes a skill from the searchData terms based on the kind of skill.
   *
   * @param {ISearchSkill} item - the item containing the skill to be removed
   * @return {void}
   */
  const removeSkill = (item: ISearchSkill) => {
    const { kind } = item.Skill
    const { skillId } = item
    const updatedTerms = { ...searchData.terms }

    switch (kind) {
      case 'skill':
        updatedTerms.skills = updatedTerms.skills.filter(skill => skill.skillId !== skillId)
        break
      case 'industry':
        updatedTerms.industries = updatedTerms.industries.filter(industry => industry.skillId !== skillId)
        break
      case 'role':
        updatedTerms.roles = updatedTerms.roles.filter(role => role.skillId !== skillId)
        break
    }

    setSearchData({
      ...searchData,
      terms: updatedTerms,
    })

    setAnchorElement(undefined)
    setItemData(undefined)
    setUpdatedItemData(undefined)
  }

  /**
   * Removes the specified item from the search data based on the given type.
   *
   * @param {ISearchLanguage | ISearchCertificate | ISearchDegree | ISearchCourse} item - the item to be removed
   * @param {string} type - the type of the item to be removed
   */
  const removeItem = (item: ISearchLanguage | ISearchCertificate | ISearchDegree | ISearchCourse, type: string) => {
    const updatedTerms = { ...searchData.terms }

    switch (type) {
      case 'language':
        updatedTerms.languages = updatedTerms.languages.filter(language => language !== item)
        break

      case 'certificate':
        updatedTerms.certificates = updatedTerms.certificates.filter(cert => cert !== item)
        break

      case 'degree':
        updatedTerms.degrees = updatedTerms.degrees.filter(degree => degree !== item)
        break

      case 'course':
        updatedTerms.courses = updatedTerms.courses.filter(course => course !== item)
        break
    }

    setSearchData({
      ...searchData,
      terms: updatedTerms,
    })
  }

  /**
   * Save a skill item in the searchData state based on the item's kind.
   *
   * @param {ISearchSkill} item - the skill item to be saved
   * @return {void}
   */
  const saveSkill = (item: ISearchSkill) => {
    let index: number
    let array: ISearchSkill[]

    switch (item.Skill.kind) {
      case 'skill':
        index = searchData.terms.skills.findIndex(skill => skill.skillId === item.skillId)
        array = searchData.terms.skills
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            skills: array.map((skill, i) => (i === index ? item : skill)),
          },
        })
        break

      case 'industry':
        index = searchData.terms.industries.findIndex(industry => industry.skillId === item.skillId)
        array = searchData.terms.industries
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            industries: array.map((industry, i) => (i === index ? item : industry)),
          },
        })
        break

      case 'role':
        index = searchData.terms.roles.findIndex(role => role.skillId === item.skillId)
        array = searchData.terms.roles
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            roles: array.map((role, i) => (i === index ? item : role)),
          },
        })
        break
    }

    setAnchorElement(undefined)
    setItemData(undefined)
    setUpdatedItemData(undefined)
  }

  const saveLanguage = (item: ISearchLanguage) => {
    const index = searchData.terms.languages.findIndex(language => language.languageCode === item.languageCode)

    setSearchData({
      ...searchData,
      terms: {
        ...searchData.terms,
        languages: searchData.terms.languages.map((language, i) => (i === index ? item : language)),
      },
    })

    setAnchorElement(undefined)
    setLanguageItem(undefined)
    setUpdatedLanguageItem(undefined)
  }

  /**
   * Function to show a chip based on the type of data provided.
   *
   * @param {ISearchSkill | ISearchLanguage | ISearchCertificate | ISearchDegree | ISearchCourse} item - the item to display as a chip
   * @param {string} type - the type of the item
   * @return {JSX.Element} the chip element to be rendered
   */
  const showChip = (
    item: ISearchSkill | ISearchLanguage | ISearchCertificate | ISearchDegree | ISearchCourse,
    type: string
  ): JSX.Element => {
    if (type === 'skill') {
      const data = item as ISearchSkill
      const handleEdit = (e: React.MouseEvent) => handleClick(e, data)

      return (
        <Chip
          key={data.skillId}
          onDelete={() => removeSkill(data)}
          onClick={handleEdit}
          label={
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid
                item
                sx={{
                  color: colors.black,
                  fontWeight: 'bold',
                }}
              >
                <span
                  style={{
                    color: colors.fontNormalColor,
                    fontWeight: 'normal',
                  }}
                >
                  {t(`search.${data.Skill.kind}.name`)}
                </span>{' '}
                {data.name}
              </Grid>
              <Grid item>
                <EditIcon color="newPrimary" fontSize="inherit" />
              </Grid>
            </Grid>
          }
          sx={{
            mr: 0.5,
            borderRadius: 0.5,
            backgroundColor: colors.chipBackground,
            color: colors.black,
            fontWeight: 'bold',
          }}
          size="small"
        />
      )
    } else if (type === 'language') {
      const data = item as ISearchLanguage

      return (
        <Chip
          key={data.languageCode}
          onDelete={() => removeItem(data, type)}
          onClick={(e: React.MouseEvent) => handleLanguageClick(e, data)}
          label={
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid
                item
                sx={{
                  color: colors.black,
                  fontWeight: 'bold',
                }}
              >
                <span
                  style={{
                    color: colors.fontNormalColor,
                    fontWeight: 'normal',
                  }}
                >
                  {t(`search.language`)}
                </span>{' '}
                {data.name}
              </Grid>
              <Grid item>
                <EditIcon color="newPrimary" fontSize="inherit" />
              </Grid>
            </Grid>
          }
          sx={{
            mr: 0.5,
            borderRadius: 0.5,
            backgroundColor: colors.chipBackground,
            color: colors.black,
            fontWeight: 'bold',
          }}
          size="small"
        />
      )
    } else {
      const handleDelete = (data: ISearchCertificate | ISearchDegree | ISearchCourse, itemType: string) => () =>
        removeItem(data, itemType)
      let data

      type === 'certificate'
        ? (data = item as ISearchCertificate)
        : type === 'course'
        ? (data = item as ISearchCourse)
        : (data = item as ISearchDegree)

      return (
        <Chip
          key={type === 'certificate' ? data.certificate : type === 'degree' ? data.degree : data.course}
          onDelete={handleDelete(data, type)}
          label={
            <Grid
              item
              sx={{
                color: colors.black,
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: colors.fontNormalColor,
                  fontWeight: 'normal',
                }}
              >
                {t(`search.${type}`)}
              </span>{' '}
              {type === 'certificate' ? data.certificate : type === 'degree' ? data.degree : data.course}
            </Grid>
          }
          sx={{
            mr: 0.5,
            borderRadius: 0.5,
            backgroundColor: colors.chipBackground,
            color: colors.black,
            fontWeight: 'bold',
          }}
          size="small"
        />
      )
    }
  }

  const { terms } = searchData

  // Checking if any term has a value
  const hasTerms = Object.values(terms).filter(term => (Array.isArray(term) ? term.length > 0 : term)).length > 0

  return (
    <Grid item xs={12} container justifyContent="space-between" mb={1}>
      <Grid item xs={10}>
        {terms.name && (
          <Chip
            label={
              <>
                <span
                  style={{
                    color: colors.fontNormalColor,
                    fontWeight: 'normal',
                  }}
                >
                  {t(`search.name`)}
                </span>{' '}
                {terms.name}
              </>
            }
            onDelete={() =>
              setSearchData({
                ...searchData,
                terms: {
                  ...terms,
                  name: '',
                },
              })
            }
            sx={{
              mr: 0.5,
              borderRadius: 0.5,
              backgroundColor: colors.chipBackground,
              color: colors.black,
              fontWeight: 'bold',
            }}
            size="small"
          />
        )}
        {terms.location && (
          <Chip
            label={
              <>
                <span
                  style={{
                    color: colors.fontNormalColor,
                    fontWeight: 'normal',
                  }}
                >
                  {t(`search.location`)}
                </span>{' '}
                {terms.location}
              </>
            }
            onDelete={() =>
              setSearchData({
                ...searchData,
                terms: {
                  ...terms,
                  location: '',
                },
              })
            }
            sx={{
              mr: 0.5,
              borderRadius: 0.5,
              backgroundColor: colors.chipBackground,
              color: colors.black,
              fontWeight: 'bold',
            }}
            size="small"
          />
        )}
        {terms.languages.length > 0 && terms.languages.map(language => showChip(language, 'language'))}
        {[...terms.skills, ...terms.roles, ...terms.industries].map(skill => showChip(skill, 'skill'))}
        {terms.certificates.length > 0 && terms.certificates.map(certificate => showChip(certificate, 'certificate'))}
        {terms.degrees.length > 0 && terms.degrees.map(degree => showChip(degree, 'degree'))}
        {terms.courses.length > 0 && terms.courses.map(course => showChip(course, 'course'))}
        {itemData && updatedItemData && (
          <EditPopover
            item={itemData}
            anchorEl={anchorElement}
            updatedItem={updatedItemData}
            setUpdatedItem={setUpdatedItemData}
            saveItem={saveSkill}
            onClose={() => {
              setItemData(undefined)
              setUpdatedItemData(undefined)
              setAnchorElement(undefined)
            }}
          />
        )}
        {languageItem && updatedLanguageItem && (
          <LanguageEditPopover
            item={languageItem}
            anchorEl={anchorElement}
            updatedItem={updatedLanguageItem}
            setUpdatedItem={setUpdatedLanguageItem}
            saveItem={saveLanguage}
            onClose={() => {
              setItemData(undefined)
              setUpdatedItemData(undefined)
              setAnchorElement(undefined)
            }}
          />
        )}
      </Grid>
      <Grid item xs="auto">
        {hasTerms && (
          <Link
            sx={{
              color: colors.newPrimary,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            underline="hover"
            onClick={() => resetSearch()}
          >
            {t('search.reset')}
          </Link>
        )}
      </Grid>
    </Grid>
  )
}

export default TermsDisplay
