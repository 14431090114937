import React, { useState, useEffect, useContext } from 'react'
import { IAccount } from 'types/userInterfaces'
import { ITeam } from 'types/teamInterfaces'
import { useLogin } from './login'
import { userAPI } from 'api/UserAPI'
import { networkAPI } from 'api/NetworkAPI'
import { personAPI } from 'api/PersonAPI'
import { adminAPI } from '../api/AdminAPI'
import { teamAPI } from 'api/TeamAPI'
import { useIsComponentMounted } from './util'
import { socket } from 'api/WebSocket'

interface UserState {
  ready: boolean
  user: IAccount | null
  groups: string[]
  networkAccess: boolean
  profileImage: string
  features: string[]
  isTeamLead: boolean
  setRefresh: (refresh: boolean) => void
  groupsReady: boolean
  update: boolean
  setUpdate: (update: boolean) => void
  setUpdateUser: (updateUser: boolean) => void
}

// The default value's type is a lie here because the default value is
// never actually used! We always give the unexported Provider a valid
// value as a prop
const userContext = React.createContext<UserState>({} as UserState)

const { Provider } = userContext

/**
 * Provider for sharing user context through the application.
 *
 * @param children
 * @returns Provider for accessing user context.
 */
export const UserProvider: React.FC<Record<string, unknown>> = ({ children }) => {
  const isComponentMounted = useIsComponentMounted()
  const [user, setUser] = useState<IAccount | null>(null)
  const [groups, setGroups] = useState<string[]>([])
  const [ready, setReady] = useState<boolean>(false)
  const [networkAccess, setNetworkAccess] = useState<boolean>(false)
  const [profileImage, setProfileImage] = useState<string>('')
  const [features, setFeatures] = useState<string[]>([])
  const [isTeamLead, setIsTeamLead] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [groupsReady, setGroupsReady] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const [updateUser, setUpdateUser] = useState<boolean>(false)

  const { loggedIn, userId } = useLogin()

  useEffect(() => {
    const controller = new AbortController()

    if ((update || loggedIn) && userId !== null) {
      ;(async () => {
        try {
          setReady(false)
          const newUser = await userAPI.getUserData(userId, controller)
          if (!isComponentMounted.current) return
          setUser(newUser)
          setGroups(Array.from(newUser.Groups.map(group => group.type)))
          setNetworkAccess(await networkAPI.checkAccess(controller))
          setFeatures(await adminAPI.getUserFeatures(controller))
          if (!isComponentMounted.current) return
          setGroupsReady(true)
          setReady(true)
          setRefresh(true)
          setUpdate(false)
        } catch (err) {
          if (!isComponentMounted.current) return
          setUser(null)
          setGroups([])
          setNetworkAccess(false)
          setGroupsReady(false)
          setReady(false)
        }
      })()
    } else {
      setUser(null)
      setGroups([])
      setGroupsReady(false)
      // The "initial" fetch is now done
      setReady(true)
      setRefresh(true)
      setUpdate(false)
    }

    return () => {
      controller.abort()
    }
  }, [loggedIn, userId, update])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (ready && user) {
        const teamsWhereLead: ITeam[] = await teamAPI.getTeamsWhereManager(controller)
        if (!isComponentMounted.current) return
        setIsTeamLead(teamsWhereLead && teamsWhereLead.length > 0 ? true : false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user, ready])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (refresh && ready) {
        if (user && user.Person) {
          const image = await personAPI.getProfileImage(user.Person.id, controller)
          if (!isComponentMounted.current) return
          setProfileImage(image)
        } else {
          setProfileImage('')
        }
        if (!isComponentMounted.current) return
        setRefresh(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user, refresh, ready])

  useEffect(() => {
    const controller = new AbortController()

    if (userId && updateUser) {
      ;(async () => {
        const result = await userAPI.getUserData(userId, controller)
        if (!isComponentMounted.current) return
        setUser(result)
        setUpdateUser(false)
      })()
    }

    return () => {
      controller.abort()
    }
  }, [updateUser])

  useEffect(() => {
    socket.on('updateUserData', () => {
      setUpdate(true)
    })

    return () => {
      socket.off('updateUserData')
    }
  }, [socket.connected])

  return React.createElement(
    Provider,
    {
      value: {
        ready,
        user,
        groups,
        networkAccess,
        profileImage,
        features,
        isTeamLead,
        setRefresh,
        groupsReady,
        update,
        setUpdate,
        setUpdateUser,
      },
    },
    children as React.ReactNode
  )
}

/**
 * Hook for getting user state.
 *
 * @returns User context variables and functions.
 */
export function useUser(): UserState {
  return useContext(userContext)
}
