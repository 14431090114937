import isHotkey, { isKeyHotkey } from 'is-hotkey'
import React, { MouseEvent, useCallback, useMemo, useState } from 'react'
import { Transforms, createEditor, Range } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react'
import { CustomTextKey } from './custom-types.d'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ImageModal from './ImageModal'
import LinkModal from './LinkModal'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import {
  serialize,
  deserialize,
  withInlines,
  withImages,
  Element,
  Leaf,
  getButtonIcon,
  isLinkActive,
  toggleMark,
  Toolbar,
  MarkButton,
  BlockButton,
  RemoveLinkButton,
  insertImage,
  insertLink,
} from './functions'

const HOTKEYS: Record<string, CustomTextKey> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

/**
 * @notExported
 */
interface ISlateEditorProps {
  /** Value of the editor */
  value: string
  /** onChange function */
  onChange: (value: string) => void
  /** label of the editor */
  label: string
  /** disabled state of the editor */
  disabled?: boolean
  /** required state of the editor */
  required?: boolean
}

const SlateEditor: React.FC<ISlateEditorProps> = ({ value, onChange, label, disabled, required }) => {
  const { t } = useTranslation()
  const [imageModal, setImageModal] = useState<boolean>(false)
  const [linkModal, setLinkModal] = useState<boolean>(false)

  const initialValue = useMemo(() => {
    if (value) {
      const document = new DOMParser().parseFromString(value, 'text/html')
      return deserialize(document.body)
    } else {
      return [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ]
    }
  }, [])

  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const editor = useMemo(() => withInlines(withImages(withHistory(withReact(createEditor())))), [])

  const InsertImageButton = () => {
    return (
      <Tooltip title={t('richTextEditor.insertImage')}>
        <IconButton
          color="primary"
          onMouseDown={(event: MouseEvent) => {
            event.preventDefault()
            setImageModal(true)
          }}
        >
          {getButtonIcon('image')}
        </IconButton>
      </Tooltip>
    )
  }

  const AddLinkButton = () => {
    return (
      <Tooltip title={t('richTextEditor.insertLink')}>
        <IconButton
          color={isLinkActive(editor) ? 'neutral' : 'primary'}
          onMouseDown={(event: MouseEvent) => {
            event.preventDefault()
            setLinkModal(true)
          }}
        >
          {getButtonIcon('link')}
        </IconButton>
      </Tooltip>
    )
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
      }
    }
  }

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      <Grid item md={12} sm={12} xs={12}>
        <CaleoInputLabel required={required} label={label + ':'} />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={value => {
            const isAstChange = editor.operations.some(op => 'set_selection' !== op.type)

            if (isAstChange) {
              let data = ''
              for (const node of value) {
                data += serialize(node)
              }

              onChange(data)
            }
          }}
        >
          <Toolbar>
            <MarkButton format="bold" icon="format_bold" />
            <MarkButton format="italic" icon="format_italic" />
            <MarkButton format="underline" icon="format_underlined" />
            <MarkButton format="code" icon="code" />
            <BlockButton format="heading-one" icon="looks_one" />
            <BlockButton format="heading-two" icon="looks_two" />
            <BlockButton format="block-quote" icon="format_quote" />
            <BlockButton format="numbered-list" icon="format_list_numbered" />
            <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            <BlockButton format="left" icon="format_align_left" />
            <BlockButton format="center" icon="format_align_center" />
            <BlockButton format="right" icon="format_align_right" />
            <BlockButton format="justify" icon="format_align_justify" />
            <InsertImageButton />
            <AddLinkButton />
            <RemoveLinkButton />
          </Toolbar>
          <Editable
            readOnly={disabled}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={onKeyDown}
            style={{
              padding: '10px',
              borderBottom: '1px solid #ccc',
              borderLeft: '1px solid #ccc',
              borderRight: '1px solid #ccc',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
          />
        </Slate>
      </Grid>
      {imageModal && (
        <ImageModal
          onClose={(image: string) => {
            if (image && image.length > 0) {
              insertImage(editor, image)
            }
            setImageModal(false)
          }}
          header={t('Select image')}
        />
      )}
      {linkModal && (
        <LinkModal
          open={linkModal}
          onClose={(url: string) => {
            if (url && url.length > 0) {
              insertLink(editor, url)
            }
            setLinkModal(false)
          }}
          header={t('Add link')}
        />
      )}
    </Grid>
  )
}

export default SlateEditor
