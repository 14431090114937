import { DialogContent, Grid } from '@mui/material'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '../InputField'
import SaveButton from 'Components/reusable/Buttons/SaveButton'

/** @notExported */
interface LinkModalProps {
  /** The function to close the modal with the selected image */
  onClose: (url?: string) => void
}

/**
 * Link modal component for Slate editor.
 *
 * @returns Link modal component.
 * @notExported
 */
const LinkModal: React.FC<LinkModalProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const [url, setUrl] = useState<string>()

  return (
    <DialogContent>
      <Grid>
        <InputField
          label={t('url')}
          onChange={e => {
            setUrl(e.target.value)
          }}
        />
      </Grid>
      <Grid>
        <SaveButton
          clickAction={() => {
            onClose(url)
          }}
          valid={!!url}
          label={t('save')}
        />
      </Grid>
    </DialogContent>
  )
}

export default withSimpleModal(LinkModal)
