import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Button, CardHeader, Grid, Tooltip } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import DraftsIcon from '@mui/icons-material/Drafts'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { useTranslation } from 'react-i18next'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import { INotification } from 'types/notificationInterfaces'
import { useNavigate } from 'react-router-dom'
import { notificationAPI } from 'api/NotificationAPI'
import { orderBy } from 'lodash'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DOMPurify from 'dompurify'
import { ColumnDef, PaginationState, VisibilityState } from '@tanstack/react-table'
import { convertToDisplayDate } from 'utils/utils'
import { useNotificationPopup } from 'Components/reusable/Notification'
import { sortByDateColumn } from 'Components/reusable/Tables/CustomTable/sortFunctions'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import FolderIcon from '@mui/icons-material/Folder'
import { useUser } from 'hooks'
import ChatIcon from '@mui/icons-material/Chat'
import { useHelpButton } from 'Components/General/HelpButtonProvider'

interface NotificationProps {
  /* Refresh notifications and unread count */
  setRefresh: Dispatch<SetStateAction<Date | undefined>>
  /* Notifications for user */
  notifications: INotification[]
}

/**
 * Notifications page.
 *
 * @returns Notifications page.
 * @notExported
 */
const Notifications: React.FC<NotificationProps> = ({ setRefresh, notifications }) => {
  const { setUrl } = useHelpButton()
  const { t, i18n } = useTranslation()
  const [backendError, setBackendError] = useState<IError>()
  const navigate = useNavigate()
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const { setNotificationPopup } = useNotificationPopup()
  const { features } = useUser()

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  })

  useEffect(() => {
    setUrl('/notifications')
  }, [])

  const setNotificationRead = async (notification: INotification) => {
    try {
      if (notification.unread) {
        const updatedNotification = await notificationAPI.setRead(notification.key)
        if (updatedNotification) {
          setRefresh(new Date())
          setNotificationPopup({ message: t('notifications.markedAsRead'), type: 'success', duration: 'short' })
        }
      }
    } catch (err) {
      setBackendError(err as IError)
    }
  }

  const setNotificationReadAll = async () => {
    try {
      await notificationAPI.setAllRead()
      setRefresh(new Date())
    } catch (err) {
      setBackendError(err as IError)
    }
  }

  const getActionButtons = (item: INotification) => {
    const profileButton = (item: INotification, personId: number): JSX.Element => {
      return (
        <CaleoIconButton
          clickAction={async () => {
            await setNotificationRead(item)
            navigate(`profile/${personId}`)
          }}
          tooltip={t('notifications.toProfile')}
          icon={<AccountBoxIcon />}
        />
      )
    }

    const assignmentButton = (item: INotification): JSX.Element => {
      return (
        <CaleoIconButton
          clickAction={async () => {
            await setNotificationRead(item)
            if (item.relatedEntities && item.relatedEntities[0].assignmentId) {
              navigate(
                `/${features.includes('assignments') ? 'assignments' : 'workorders'}/${
                  item.relatedEntities[0].assignmentId
                }`
              )
            }
          }}
          tooltip={t('notifications.toAssignment')}
          icon={<AssignmentIcon />}
        />
      )
    }

    const proposedButton = (item: INotification): JSX.Element => {
      return (
        <CaleoIconButton
          clickAction={async () => {
            await setNotificationRead(item)
            if (item.relatedEntities && item.relatedEntities[0].assignmentId) {
              navigate(
                `/${features.includes('assignments') ? 'assignments' : 'workorders'}/proposed/${
                  item.relatedEntities[0].assignmentId
                }`
              )
            }
          }}
          tooltip={t('notifications.toProposed')}
          icon={<FolderSharedIcon />}
        />
      )
    }

    const responseButton = (item: INotification): JSX.Element => {
      return (
        <CaleoIconButton
          clickAction={async () => {
            await setNotificationRead(item)
            if (item.relatedEntities && item.relatedEntities[0].responseId) {
              navigate(
                `/${features.includes('assignments') ? 'assignments' : 'workorders'}/responses/${
                  item.relatedEntities[0].responseId
                }`
              )
            }
          }}
          tooltip={t('notifications.toResponse')}
          icon={<FolderIcon />}
        />
      )
    }

    const chatButton = (item: INotification): JSX.Element => {
      return (
        <CaleoIconButton
          clickAction={async () => {
            await setNotificationRead(item)
            if (item.relatedEntities && item.relatedEntities[0].roomName) {
              navigate(`/chat/${item.relatedEntities[0].roomName}`)
            }
          }}
          tooltip={t('notifications.toChat')}
          icon={<ChatIcon />}
        />
      )
    }

    const proposalButton = (item: INotification): JSX.Element => {
      return (
        <CaleoIconButton
          clickAction={async () => {
            await setNotificationRead(item)
            if (item.relatedEntities && item.relatedEntities[0].proposalId) {
              navigate(
                `/${features.includes('assignments') ? 'assignments' : 'workorders'}/proposals/${
                  item.relatedEntities[0].proposalId
                }`
              )
            }
          }}
          tooltip={t('notifications.toProposal')}
          icon={<FolderSharedIcon />}
        />
      )
    }

    switch (item.type) {
      case 'new-assignment':
      case 'assignment-addition':
      case 'contact-new-assignment-notification':
        if (item.relatedEntities && item.relatedEntities[0].assignmentId) {
          return assignmentButton(item)
        }
        break

      case 'workorder-response-rejected':
      case 'new-work-order-response':
      case 'work-order-response-received':
      case 'workorder-response-accepted':
        if (item.relatedEntities && item.relatedEntities[0].responseId) {
          return responseButton(item)
        }
        break

      case 'assignment-end-reminder':
      case 'new-role-note':
        if (item.relatedEntities && item.relatedEntities[0].assignmentId) {
          return proposedButton(item)
        }
        break

      case 'proposal-reject':
      case 'proposal-accept':
      case 'proposal-auto-close':
        if (item.relatedEntities && item.relatedEntities[0].proposalId) {
          return proposalButton(item)
        }
        break

      case 'chat-message':
      case 'chat-invite':
        if (item.relatedEntities && item.relatedEntities[0].roomName) {
          return chatButton(item)
        }
        break

      case 'proposal-remove':
        if (item.relatedEntities && (item.relatedEntities[0].personId || item.relatedEntities[0].assignmentId)) {
          return (
            <>
              {item.relatedEntities &&
                item.relatedEntities[0].personId &&
                profileButton(item, item.relatedEntities[0].personId)}
              {assignmentButton(item)}
            </>
          )
        }
        break

      case 'role-proposal':
        if (item.relatedEntities && item.relatedEntities[0].assignmentId) {
          const profileButtons: JSX.Element[] = []

          for (const entity of item.relatedEntities) {
            if (entity.personId) {
              profileButtons.push(profileButton(item, entity.personId))
            }
          }

          return (
            <>
              {profileButtons}
              {assignmentButton(item)}
            </>
          )
        }
        break

      case 'cv-update-reminder':
        return (
          <CaleoIconButton
            clickAction={async () => {
              await setNotificationRead(item)
              navigate(`/profile`)
            }}
            tooltip={t('notifications.toProfile')}
            icon={<AccountBoxIcon />}
          />
        )

      case 'network-unsub':
        if (item.relatedEntities && item.relatedEntities[0].personId) {
          return profileButton(item, item.relatedEntities[0].personId)
        }
        break

      default:
        return null
    }
  }

  const columns = useMemo<ColumnDef<INotification>[]>(
    () => [
      {
        id: 'status',
        header: t('notifications.status'),
        accessorFn: row => (row.unread ? 'false' : 'true'),
        cell: ({ row }) => {
          const icon = !row.original.unread ? (
            <DraftsIcon />
          ) : (
            <EmailIcon style={{ cursor: 'pointer' }} onClick={() => setNotificationRead(row.original)} />
          )
          return (
            <Tooltip title={String(row.original.unread ? t('notifications.unread') : t('notifications.read'))}>
              {icon}
            </Tooltip>
          )
        },
        maxSize: 24,
      },
      {
        id: 'description',
        header: t('notifications.description'),
        accessorFn: row => (i18n.language === 'fi' ? DOMPurify.sanitize(row.text_fi) : DOMPurify.sanitize(row.text_en)),
        cell: ({ row }) => {
          if (i18n.language === 'fi') {
            const html = DOMPurify.sanitize(row.original.text_fi.replaceAll('cid:caleoLogo.png', 'caleoLogo.svg'))

            return <div dangerouslySetInnerHTML={{ __html: html }} />
          } else {
            const html = DOMPurify.sanitize(row.original.text_fi.replaceAll('cid:caleoLogo.png', 'caleoLogo.svg'))

            return <div dangerouslySetInnerHTML={{ __html: html }} />
          }
        },
        size: 600,
      },
      {
        id: 'createdAt',
        header: t('createdAt'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        accessorFn: row => {
          return convertToDisplayDate(row.createdAt)
        },
        maxSize: 50,
      },
      {
        id: 'controls',
        enableHiding: false,
        cell: ({ row }) => {
          return <>{getActionButtons(row.original)}</>
        },
        maxSize: 48,
      },
    ],
    [notifications, i18n.language]
  )

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <Grid sx={{ maxWidth: 1400, m: '0 auto' }}>
      <CardHeader title={t('notifications.title')} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button onClick={async () => await setNotificationReadAll()} color="primary" variant="contained">
            {t('notifications.readAll')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={columns}
            data={orderBy(notifications, ['createdAt'], ['desc'])}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            setPagination={setPagination}
            pageIndex={pageIndex}
            pageSize={pageSize}
            elevation={0}
            newStyle
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Notifications
