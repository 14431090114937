import DateRangeIcon from '@mui/icons-material/DateRange'
import CloseIcon from '@mui/icons-material/Close'
import ReportIcon from '@mui/icons-material/Report'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from '@mui/material'
import MDEditor from '@uiw/react-md-editor'
import { i18n, TFunction } from 'i18next'
import _ from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import DatePicker, { DatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { IWidget } from 'types/adminInterfaces'
import { IAllocation } from 'types/allocationInterfaces'
import { IAssignment, IAssignmentRole } from 'types/assignmentInterfaces'
import {
  IEmployer,
  ILanguageLevel,
  ILanguageSkill,
  ILevel,
  ISkillInterestLevel,
  ISkillLevel,
  ISkillOrIndustryOrRole,
} from 'types/cvsInterfaces'
import {
  AssignmentId,
  AssignmentRoleId,
  CountryCode,
  CvId,
  EmployerId,
  Id,
  LanguageCode,
  OrganizationId,
  PersonId,
  SiteId,
  SkillId,
  StateCode,
  TypeCode,
} from 'types/ids'
import { IAssignmentVisibility, INetwork, INetworkManager } from 'types/networkInterfaces'
import { IOrganizationType, ISearchFields } from 'types/searchInterfaces'
import { IOrganization, IPerson, ISite } from 'types/userInterfaces'
import {
  chooseDBTranslation,
  chooseDBTranslationIndex,
  getLanguageDataMap,
  isTranslateableObject,
  Translateable,
  TranslateableObject,
} from 'utils/translations'
import { formatEndDate, formatStartDate, getDateFormatByLocaleAndResolution } from 'utils/utils'
import { Schema, ValidationError } from 'yup'
import CaleoSecondaryButton from '../Buttons/CaleoSecondaryButton'
import CardContentText from '../CaleoCustomComponents/CardContentText'
import ModalPaper from '../CaleoCustomComponents/ModalPaper'
import AssignmentPicker from '../InputFields/AssignmentPicker'
import AssignmentVisibilityPicker from '../InputFields/AssignmentVisibilityPicker'
import ColorPicker from '../InputFields/ColorPicker'
import ContactPeoplePicker from '../InputFields/ContactPeoplePicker'
import CountryPicker from '../InputFields/CountryPicker'
import EmployerPicker from '../InputFields/EmployerPicker'
import { ExperienceTimePicker } from '../InputFields/ExperienceTimePicker'
import InterestLevelPicker from '../InputFields/InterestLevelPicker'
import LanguagePicker from '../InputFields/LanguagePicker'
import LevelPicker from '../InputFields/LevelPicker'
import ManagersPicker from '../InputFields/ManagersPicker'
import { NetworkPicker } from '../InputFields/NetworkPicker'
import NetworkTypePicker, { ITypeOption } from '../InputFields/NetworkTypePicker'
import OrganizationPicker from '../InputFields/OrganizationPicker'
import OrganizationTypePicker from '../InputFields/OrganizationTypePicker'
import PersonPicker from '../InputFields/PersonPicker'
import RolePicker from '../InputFields/RolePicker'
import SitePicker from '../InputFields/SitePicker'
import SkillPicker from '../InputFields/SkillPicker'
import StatePicker from '../InputFields/StatePicker'
import TypePicker from '../InputFields/TypePicker'
import { InputField } from '../InputFields/InputField'
import { NumberSliderField } from '../InputFields/NumberSliderField'
import RichTextEditor from '../InputFields/RichTextEditor'
import CaleoInputLabel from '../CaleoCustomComponents/CaleoInputLabel'
import { useIsComponentMounted } from 'hooks/util'
import PhoneNumberInput from '../InputFields/PhoneNumberInput'
import SlateEditor from '../InputFields/SlateEditor'

type AnyObject = Record<never, unknown>

type EditableFields<T, K = T extends TranslateableObject<infer S> ? S : AnyObject> = Omit<
  T & K,
  keyof Translateable | 'id' | 'createdAt' | 'updatedAt'
>

type KeysOfType<T, K> = { [L in keyof T]: L extends K ? L : never }[keyof T]
type FieldsOfType<T, K, E = never> = {
  [L in KeysOfType<T, string>]: T[L] extends K ? (T[L] extends E ? never : L) : never
}[KeysOfType<T, string>]

type EditableFieldsOfType<T, K, E = never> = FieldsOfType<EditableFields<T>, K, E>

type ErrorFields<T> = {
  [L in keyof T]?: string
}
type TouchedFields<T> = {
  [L in keyof T]?: boolean
}
type DisabledFields<T> = {
  [L in keyof T]?: boolean
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type NewOrExisting<T> = Optional<T, keyof T & 'id'>

interface ContextValue<T extends AnyObject> {
  data: T
  valid: boolean
  errors: ErrorFields<EditableFields<T>>
  combinedErrors: string[]
  touched: TouchedFields<EditableFields<T>>
  disabled: DisabledFields<EditableFields<T>>
  allTouched: boolean
  hasTranslation: boolean
  flatData: EditableFields<T>
  setFields: (change: { [key: string]: unknown }) => unknown
  onChange: (data: T) => unknown
  reloadTranslation: () => void
  handleSubmit: (e) => void
  localeBase: string
  t: TFunction
  i18n: i18n
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Context = React.createContext<ContextValue<any>>(null as any)

/**
 * Data edit context.
 *
 * @param data - Data to edit.
 * @param localeBase - Base locale for translations.
 * @param onChange - Function to call when the data changes.
 * @param schema - Validation schema.
 * @param children - Children to render.
 * @param onSubmit - Function to call when the form is submitted.
 * @returns Data context provider.
 * @notExported
 */
const DataEditContext = <T extends AnyObject>({
  data,
  localeBase,
  onChange,
  schema,
  children,
  onSubmit,
}: {
  data: T
  localeBase?: string
  onChange: (data: T) => unknown
  schema?: Schema<unknown>
  children: React.ReactNode
  onSubmit?: () => void
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const [errors, setErrors] = useState<ErrorFields<EditableFields<T>>>({})
  const [combinedErrors, setCombinedErrors] = useState<string[]>([])
  const [touched, setTouched] = useState<TouchedFields<EditableFields<T>>>({})
  const [allTouched, setAllTouched] = useState<boolean>(false)
  const [translationCacheBuster, setTranslationCacheBuster] = useState(0)

  const reloadTranslation = () => {
    if (isComponentMounted.current) setTranslationCacheBuster(translationCacheBuster + 1)
  }

  const translation = useMemo(() => {
    if (!isTranslateableObject(data)) {
      return null
    }

    return chooseDBTranslation(i18n, data)
  }, [i18n.language, translationCacheBuster])

  const translationFields = useMemo(() => {
    const result: string[] = []

    if (!translation) {
      return result
    }

    for (const key in translation) {
      if (!['id', 'createdAt', 'updatedAt', 'languageId', 'Language'].includes(key)) {
        result.push(key)
      }
    }

    return result
  }, [translation])

  const hasTranslation = (translation as Translateable)?.Language?.name === i18n.language

  const disabled: DisabledFields<EditableFields<T>> = useMemo(() => {
    if (hasTranslation) {
      return {}
    }

    const result = {}
    for (const key of translationFields) {
      result[key] = true
    }
    return result
  }, [hasTranslation, translationFields])

  const flatData: EditableFields<T> = useMemo(
    () =>
      Object.assign(
        {},
        data,
        isTranslateableObject(data) ? chooseDBTranslation(i18n, data) : undefined
      ) as unknown as EditableFields<T>,
    [data, i18n.language]
  )

  useEffect(() => {
    if (!schema) {
      setErrors({})
      setCombinedErrors([])
      return
    }

    ;(async () => {
      try {
        await schema.validate(flatData, { abortEarly: false })
        if (isComponentMounted.current) {
          setErrors({})
          setCombinedErrors([])
        }
      } catch (err) {
        if (!(err instanceof ValidationError)) {
          throw err
        }

        const newErrors: typeof errors = {}

        for (const inner of err.inner) {
          const path = inner.path
          if (allTouched || touched[path]) {
            newErrors[path] = inner.message
          }
        }
        if (isComponentMounted.current) {
          setErrors(newErrors)
          setCombinedErrors(err.errors)
        }
      }
    })()
  }, [flatData, schema, allTouched])

  const setFields = (change: { [key: string]: unknown }) => {
    let newData = data

    const newlyTouched: TouchedFields<EditableFields<T>> = {}

    for (const field in change) {
      const newValue = change[field]

      newlyTouched[field] = true

      if (isTranslateableObject(newData)) {
        let translationIndex = chooseDBTranslationIndex(i18n, newData.translations)
        if (translationIndex < 0) {
          translationIndex = 0
        }

        if (field in newData.translations[translationIndex]) {
          newData = {
            ...newData,
            translations: [
              ...newData.translations.slice(0, translationIndex),
              {
                ...newData.translations[translationIndex],
                [field]: newValue,
              },
              ...newData.translations.slice(translationIndex + 1),
            ],
          }

          continue
        }
      }

      newData = {
        ...newData,
        [field]: newValue,
      }
    }

    if (isComponentMounted.current)
      setTouched({
        ...touched,
        ...newlyTouched,
      })

    onChange(newData)
  }

  const Provider = (Context as unknown as React.Context<ContextValue<T>>).Provider

  const handleSubmit = (event: KeyboardEvent) => {
    if (onSubmit && (event.key === 'Enter' || event.key === 'NumpadEnter')) {
      event.preventDefault()
      if (isComponentMounted.current) {
        setAllTouched(true)
        if (combinedErrors.length === 0) {
          onSubmit()
        }
      }
    }
  }

  return (
    <Provider
      value={{
        data,
        valid: combinedErrors.length === 0,
        errors,
        combinedErrors,
        touched,
        disabled,
        allTouched,
        hasTranslation,
        flatData,
        setFields,
        onChange,
        reloadTranslation,
        handleSubmit,
        localeBase: localeBase ?? 'datacontext',
        t,
        i18n,
      }}
    >
      {children}
    </Provider>
  )
}

/**
 * Data context validation function
 *
 * @param children - Children to render.
 * @returns Data context validation component.
 * @notExported
 */
const DataEditContextValidation = <T,>({
  children,
}: {
  children: ({ valid, errors }: { valid: boolean; errors: string[] }) => T
}) => {
  const { valid, combinedErrors } = useContext(Context)

  return children({ valid, errors: combinedErrors })
}
DataEditContext.Validation = DataEditContextValidation

/**
 * Data edit context translation import component.
 *
 * @returns Data translation import elements.
 * @notExported
 */
const DataEditContextTranslationImport = <T extends AnyObject>() => {
  const { data, hasTranslation, setFields, reloadTranslation, i18n, t, valid } = useContext<ContextValue<T>>(Context)

  if (hasTranslation) {
    return null
  }

  const createTranslation = async (isNew?: boolean) => {
    const languageData = await getLanguageDataMap()
    const newLanguage = languageData[i18n.language]
    const translation = chooseDBTranslation(i18n, data as unknown as TranslateableObject<Translateable>)
    const objectKeys = { ..._.cloneDeep(translation) }
    if (isNew) {
      for (const [key] of Object.entries(objectKeys)) {
        if (key !== 'id' && key !== 'Language') {
          objectKeys[key] = ''
        }
      }
    }
    const newTranslation = {
      ..._.cloneDeep(objectKeys),
      id: null,
      languageId: newLanguage.id,
      Language: newLanguage,
      createdAt: new Date(),
      updatedAt: new Date(),
    }

    setFields({
      translations: [...(data as unknown as TranslateableObject<Translateable>).translations, newTranslation],
    })

    reloadTranslation()
  }

  const showImportInstructions = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <Grid item>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <ReportIcon color="error" fontSize="large" />
            </Grid>
            <Grid item>
              <h4>{t('translationImport.missing', { lang: i18n.language.toUpperCase() })}</h4>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginBottom: 15 }}>
          <CardContentText type="description">
            {t('translationImport.description', { lang: i18n.language.toUpperCase() })}
          </CardContentText>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <CaleoSecondaryButton
                id="addNewTranslation"
                clickAction={() => createTranslation(true)}
                label={t('translationImport.addNew', { lang: existingTranslation.Language?.name.toUpperCase() })}
                valid={true}
              />
            </Grid>
            <Grid item>
              <CaleoSecondaryButton
                id="copyOldTranslation"
                clickAction={() => createTranslation()}
                label={t('translationImport.addOld', { lang: existingTranslation.Language?.name.toUpperCase() })}
                valid={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const showError = () => {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid>
          <ReportIcon color="error" fontSize="large" />
        </Grid>
        <Grid>
          <h4>{t('translationImport.dataMissing')}</h4>
        </Grid>
      </Grid>
    )
  }

  const showInfo = () => {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid>
          <ReportIcon color="error" fontSize="large" />
        </Grid>
        <Grid>
          <h4>{t('translationImport.noTranslation')}</h4>
        </Grid>
      </Grid>
    )
  }

  const existingTranslation = chooseDBTranslation(i18n, data as unknown as TranslateableObject<Translateable>)

  return (
    <Box p={{ md: 3, sm: 1, xs: 0 }} mt={{ xs: 1 }}>
      {valid ? (
        <ModalPaper>{existingTranslation.Language ? showImportInstructions() : showError()}</ModalPaper>
      ) : (
        <ModalPaper>{showInfo()}</ModalPaper>
      )}
    </Box>
  )
}
DataEditContext.TranslationImport = DataEditContextTranslationImport

/**
 * Password field.
 *
 * @param field - Field name.
 * @param showPassowordOrHide - Show or hide password.
 * @param rest - Other props.
 * @returns Data context password field.
 * @notExported
 */
const DataEditContextPasswordField = <T extends AnyObject>({
  field,
  showPassowordOrHide,
  ...rest
}: { field: EditableFieldsOfType<T, string>; showPassowordOrHide?: boolean } & Omit<
  Record<string, unknown>,
  'onChange' | 'value' | 'error' | 'name'
>) => {
  const { flatData, errors, disabled, setFields, handleSubmit, localeBase, t } = useContext<ContextValue<T>>(Context)

  const [value, setValue] = useState<string>(flatData[field] as unknown as string)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const error = errors[field]

  const setField = useMemo(() => (newValue: string) => setFields({ [field]: newValue }), [setFields, field])

  useEffect(() => {
    setValue(flatData[field] as unknown as string)
  }, [flatData[field]])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setValue(newValue)
    setField(newValue)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <InputField
      onKeyDown={e => handleSubmit(e)}
      onChange={handleChange}
      value={value ?? ''}
      error={error}
      disabled={disabled[field]}
      name={field}
      label={t(`${localeBase}.${field}`)}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <>
            {showPassowordOrHide && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
      {...rest}
    />
  )
}
DataEditContext.PasswordField = DataEditContextPasswordField

/**
 * Text field.
 *
 * @param field - field name.
 * @param maxHintLength - max hint length.
 * @param multiline - is field multiline.
 * @param type - field type.
 * @param resetError - reset error function.
 * @param icon - icon.
 * @param id - field id.
 * @param hideLabel - Should label be hidden.
 * @param rest - other props.
 * @returns Data context text field.
 * @notExported
 */
const DataEditContextTextField = <T extends AnyObject>({
  field,
  maxHintLength,
  multiline,
  type,
  resetError,
  icon,
  id,
  hideLabel,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
  maxHintLength?: number
  multiline?: boolean
  type?: string
  resetError?: () => void
  icon?: JSX.Element
  id?: string
  hideLabel?: boolean
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, errors, disabled, setFields, handleSubmit, localeBase, t } = useContext<ContextValue<T>>(Context)

  const [value, setValue] = useState<string>(flatData[field] as unknown as string)

  const error = errors[field]

  const setField = useMemo(() => (newValue: string) => setFields({ [field]: newValue }), [setFields, field])

  useEffect(() => {
    setValue(flatData[field] as unknown as string)
  }, [flatData[field]])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setValue(newValue)
    setField(newValue)
    if (resetError) resetError()
  }

  return (
    <InputField
      id={id}
      icon={icon}
      onKeyDown={multiline ? undefined : e => handleSubmit(e)}
      multiline={multiline}
      onChange={handleChange}
      value={value ?? ''}
      error={error}
      disabled={disabled[field]}
      name={field}
      label={!hideLabel ? t(`${localeBase}.${field}`) : undefined}
      type={type}
      insetLabel={insetLabel}
      helperText={
        error ?? (maxHintLength ? `${t(`${localeBase}.charLeft`)} ${maxHintLength - (value ? value.length : 0)}` : null)
      }
      {...rest}
    />
  )
}
DataEditContext.TextField = DataEditContextTextField

/**
 * Number field.
 *
 * @param field - Field name.
 * @param noLabel - Should label be hidden.
 * @param rest - Other props.
 * @returns Data context number field.
 * @notExported
 */
const DataEditContextNumberField = <T extends AnyObject>({
  field,
  noLabel,
  ...rest
}: { field: EditableFieldsOfType<T, number, Id>; noLabel?: boolean } & Omit<
  Record<string, unknown>,
  'onChange' | 'value' | 'error' | 'name'
>) => {
  const { flatData, errors, disabled, setFields, handleSubmit, localeBase, t } = useContext<ContextValue<T>>(Context)

  const [value, setValue] = useState<string>((flatData[field] as unknown as number)?.toString() ?? null)
  const [focused, setFocused] = useState<boolean>(false)
  const error = errors[field]

  const setField = useMemo(
    () =>
      _.debounce((newValue: string) => {
        const numberValue = parseFloat(newValue)
        setFields({ [field]: numberValue })
      }, 300),
    [setFields, field]
  )

  const updateFromData = () => {
    if (!focused) {
      setValue((flatData[field] as unknown as number)?.toString() ?? null)
    }
  }

  useEffect(() => {
    updateFromData()
  }, [focused, flatData[field]])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setValue(newValue)
    setField(newValue)
  }

  return (
    <InputField
      onKeyDown={e => handleSubmit(e)}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type="number"
      value={value ?? null}
      error={error}
      disabled={disabled[field]}
      name={field}
      label={!noLabel ? t(`${localeBase}.${field}`) : undefined}
      {...rest}
    />
  )
}
DataEditContext.NumberField = DataEditContextNumberField

/**
 * Number slider field.
 *
 * @param field - Field name.
 * @param type - Field type.
 * @param smallerScale - Should smaller scale be used.
 * @param rest - Other props.
 * @returns Data context number slider field.
 * @notExported
 */
const DataEditContextNumberSliderField = <T extends AnyObject>({
  field,
  type,
  smallerScale,
  ...rest
}: { field: EditableFieldsOfType<T, number, Id>; type?: string; smallerScale?: boolean } & Omit<
  Record<string, unknown>,
  'onChange' | 'value' | 'error' | 'name'
>) => {
  const { flatData, setFields } = useContext<ContextValue<T>>(Context)

  const [value, setValue] = useState<string>((flatData[field] as unknown as number)?.toString() ?? null)

  const setField = useMemo(
    () =>
      _.debounce((newValue: string) => {
        const numberValue = parseInt(newValue)
        setFields({ [field]: numberValue })
      }, 300),
    [setFields, field]
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setValue(newValue)
    setField(newValue)
  }

  return (
    <NumberSliderField
      type={type}
      value={parseInt(value)}
      onChange={handleChange}
      smallerScale={smallerScale}
      {...rest}
    />
  )
}
DataEditContext.NumberSliderField = DataEditContextNumberSliderField

/**
 * Experience time field.
 *
 * @param field - Field name.
 * @param expandedInputOptions - Should expanded input options be used.
 * @param rest - Other props.
 * @returns Data context experience time field.
 * @notExported
 */
const DataEditContextExperienceTimeField = <T extends AnyObject>({
  field,
  expandedInputOptions,
  ...rest
}: { field: EditableFieldsOfType<T, number, Id>; expandedInputOptions?: boolean } & Omit<
  Record<string, unknown>,
  'onChange' | 'value' | 'error' | 'name'
>) => {
  const { flatData, errors, disabled, setFields, handleSubmit, localeBase, t } = useContext<ContextValue<T>>(Context)

  useEffect(() => {
    if ((flatData[field] as unknown as number) === 0) setValue(flatData[field] as unknown as string)
  }, [flatData[field]])

  const [value, setValue] = useState<string>((flatData[field] as unknown as number).toString())
  const [inputType, setInputType] = useState<string>('months')

  const error = errors[field]

  const setField = useMemo(
    () =>
      _.debounce((newValue: string, inputType: string) => {
        const numberValue = parseFloat(newValue)
        if (inputType === 'months') {
          setFields({ [field]: numberValue })
        } else if (expandedInputOptions) {
          if (inputType === 'days') {
            // average amount of days in a month is 30.436875
            setFields({ [field]: numberValue / 30.436875 })
          } else if (inputType === 'manMonths') {
            // 1 man-months = 1.369863 months (1 month = 0.73 man-months)
            setFields({ [field]: numberValue * 1.369863 })
          } else if (inputType === 'manDays') {
            // average amount of man-days in month is 22
            setFields({ [field]: numberValue / 22 })
          } else {
            setFields({ [field]: numberValue * 12 })
          }
        } else {
          // input in Years
          setFields({ [field]: numberValue * 12 })
        }
      }, 300),
    [setFields, field]
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setField(newValue, inputType)
    setValue(newValue)
  }

  return (
    <ExperienceTimePicker
      expandedInputOptions={expandedInputOptions}
      onKeyDown={e => handleSubmit(e)}
      onChange={handleChange}
      inputType={inputType}
      setInputType={type => {
        setInputType(type)
        setField(value, type)
      }}
      type="number"
      value={value ?? null}
      error={error}
      disabled={disabled[field]}
      name={field}
      label={t(`${localeBase}.${field}`)}
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
      {...rest}
    />
  )
}
DataEditContext.ExperienceTimeField = DataEditContextExperienceTimeField

type NullableNumber = number | null

/**
 * Nullable number field.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context nullable number field.
 * @notExported
 */
// TODO: make this component not accept non-nullable number fields
const DataEditContextNullableNumberField = <T extends AnyObject>({
  field,
  ...rest
}: { field: EditableFieldsOfType<T, NullableNumber, Id> } & Omit<
  Record<string, unknown>,
  'onChange' | 'value' | 'error' | 'name'
>) => {
  const { flatData, errors, disabled, setFields, handleSubmit, localeBase, t } = useContext<ContextValue<T>>(Context)

  const [value, setValue] = useState<string>((flatData[field] as unknown as NullableNumber)?.toString() ?? '')
  const [focused, setFocused] = useState<boolean>(false)
  const error = errors[field]

  const setField = useMemo(
    () =>
      _.debounce((newValue: string) => {
        const numberValue = newValue === '' ? null : parseFloat(newValue)
        setFields({ [field]: numberValue })
      }, 300),
    [setFields, field]
  )

  const updateFromData = () => {
    if (!focused) {
      setValue((flatData[field] as unknown as NullableNumber)?.toString() ?? '')
    }
  }

  useEffect(() => {
    updateFromData()
  }, [focused, flatData[field]])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setValue(newValue)
    setField(newValue)
  }

  return (
    <InputField
      onKeyDown={e => handleSubmit(e)}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type="number"
      value={value}
      error={error}
      disabled={disabled[field]}
      name={field}
      label={t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.NullableNumberField = DataEditContextNullableNumberField

/**
 * Date field.
 *
 * @param field - Field name.
 * @param dateResolution - Date resolution.
 * @param label - Label.
 * @param minDate - Minimum date.
 * @param isClearable - Is clearable.
 * @param rest - Other props.
 * @returns Data context date field.
 * @notExported
 */
const DataEditContextDateField = <T extends AnyObject>({
  id,
  field,
  dateResolution,
  label,
  start,
  end,
  minDate,
  isClearable,
  insetLabel,
  required = false,
}: {
  id?: string
  field: EditableFieldsOfType<T, Date | null>
  dateResolution?: 'day' | 'month' | 'year'
  label?: string
  minDate?: Date
  start?: boolean
  end?: boolean
  isClearable?: boolean
  insetLabel?: boolean
  required?: boolean
} & Omit<DatePickerProps, 'onChange' | 'dateResolution'>) => {
  const { flatData, disabled, errors, setFields, localeBase, t, i18n } = useContext<ContextValue<T>>(Context)

  const dateResolutionValue = dateResolution ?? flatData['dateResolution'] ?? 'month'

  const handleChange = (newValue: Date | null) => {
    // TODO: handle ranges (`[Date, Date]`)?

    const alteredDate = newValue
    if (alteredDate !== null) {
      if (start) {
        formatStartDate(alteredDate, dateResolutionValue)
      } else if (end) {
        formatEndDate(alteredDate, dateResolutionValue)
      }
    }
    setFields({ [field]: alteredDate })
  }

  const value = flatData[field] as unknown as Date | null

  let error = errors[field]

  if (!error && value && minDate) {
    if ((dateResolutionValue === 'day' || !dateResolutionValue) && value < minDate) {
      error = i18n.t('startDateEndDateError')
    }
    if (dateResolutionValue === 'month') {
      const monthValue = new Date(value)
      monthValue.setDate(1)
      const earliestSelectableMonth = new Date(minDate)
      earliestSelectableMonth.setDate(1)
      if (monthValue < earliestSelectableMonth) error = i18n.t('startDateEndDateError')
    }
    if (dateResolutionValue === 'year' && value < minDate) {
      const yearValue = new Date(value)
      yearValue.setDate(1)
      yearValue.setMonth(0)
      const earliestSelectableYear = new Date(minDate)
      earliestSelectableYear.setDate(1)
      earliestSelectableYear.setMonth(0)
      if (yearValue < earliestSelectableYear) error = i18n.t('startDateEndDateError')
    }
  }

  const locale = i18n.language
  const format = getDateFormatByLocaleAndResolution(locale, dateResolutionValue)

  const handleClear = () => {
    setFields({ [field]: null })
  }

  return (
    <DatePicker
      id={id}
      selected={typeof value === 'string' ? new Date(value) : value}
      onChange={handleChange}
      dateFormat={format}
      showMonthYearPicker={dateResolutionValue === 'month'}
      showYearPicker={dateResolutionValue === 'year'}
      disabled={disabled[field]}
      minDate={minDate}
      customInput={
        <InputField
          label={label ? t(label) : t(`${localeBase}.${field}`)}
          fullWidth
          disabled={disabled[field]}
          error={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isClearable && value && (
                  <IconButton onClick={handleClear} onMouseDown={handleClear} size="small" sx={{ p: 0, m: 0 }}>
                    <CloseIcon style={{ cursor: 'pointer' }} fontSize="small" />
                  </IconButton>
                )}
                <DateRangeIcon color="secondary" style={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
          insetLabel={insetLabel}
          required={required}
        />
      }
      popperPlacement="bottom-start"
      showMonthDropdown
      useShortMonthInDropdown
      showYearDropdown
      required={required}
    />
  )
}
DataEditContext.DateField = DataEditContextDateField

/**
 * Date field without label.
 *
 * @param field - Field name.
 * @param dateResolution - Date resolution.
 * @param label - Label.
 * @param minDate - Minimum date.
 * @param maxDate - Maximum date.
 * @param isClearable - Is clearable.
 * @param rest - Other props.
 * @returns Data context date field.
 * @notExported
 */
const DataEditContextDatePickerField = <T extends AnyObject>({
  field,
  dateResolution,
  start,
  end,
  minDate,
  maxDate,
  isClearable,
}: {
  field: EditableFieldsOfType<T, Date | null>
  dateResolution?: 'day' | 'month' | 'year'
  minDate?: Date
  maxDate?: Date
  start?: boolean
  end?: boolean
  isClearable?: boolean
} & Omit<DatePickerProps, 'onChange' | 'dateResolution'>) => {
  const { flatData, disabled, errors, setFields, i18n } = useContext<ContextValue<T>>(Context)

  const dateResolutionValue = dateResolution ?? flatData['dateResolution'] ?? 'month'

  const handleChange = (newValue: Date | null) => {
    // TODO: handle ranges (`[Date, Date]`)?

    if (newValue) {
      if (start) {
        newValue = formatStartDate(newValue, dateResolutionValue)
      } else if (end) {
        newValue = formatEndDate(newValue, dateResolutionValue)
      }
    }
    setFields({ [field]: newValue })
  }

  const value = flatData[field] as unknown as Date | null

  let error = errors[field]

  if (!error && value) {
    if ((dateResolutionValue === 'day' || !dateResolutionValue) && minDate && value < minDate) {
      error = i18n.t('startDateEndDateError')
    }
    if (dateResolutionValue === 'month' && maxDate && value > maxDate) {
      error = i18n.t('endDateStartDateError')
    }
    if (dateResolutionValue === 'month') {
      const monthValue = new Date(value)
      monthValue.setDate(1)
      if (minDate) {
        const earliestSelectableMonth = new Date(minDate)
        earliestSelectableMonth.setDate(1)
        if (monthValue < earliestSelectableMonth) error = i18n.t('startDateEndDateError')
      }
      if (maxDate) {
        const latestSelectableMonth = new Date(maxDate)
        latestSelectableMonth.setDate(1)
        if (monthValue > latestSelectableMonth) error = i18n.t('endDateStartDateError')
      }
    }
    if (dateResolutionValue === 'year') {
      const yearValue = new Date(value)
      yearValue.setDate(1)
      yearValue.setMonth(0)
      if (minDate) {
        const earliestSelectableYear = new Date(minDate)
        earliestSelectableYear.setDate(1)
        earliestSelectableYear.setMonth(0)
        if (yearValue < earliestSelectableYear) error = i18n.t('startDateEndDateError')
      }
      if (maxDate) {
        const latestSelectableYear = new Date(maxDate)
        latestSelectableYear.setDate(1)
        latestSelectableYear.setMonth(0)
        if (yearValue > latestSelectableYear) error = i18n.t('endDateStartDateError')
      }
    }
  }

  const locale = i18n.language
  const format = getDateFormatByLocaleAndResolution(locale, dateResolutionValue)

  const handleClear = () => {
    setFields({ [field]: null })
  }

  return (
    <DatePicker
      selected={value}
      onChange={handleChange}
      dateFormat={format}
      showMonthYearPicker={dateResolutionValue === 'month'}
      showYearPicker={dateResolutionValue === 'year'}
      disabled={disabled[field]}
      minDate={minDate}
      maxDate={maxDate}
      customInput={
        <TextField
          disabled={disabled[field]}
          helperText={error}
          error={!!error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isClearable && value && (
                  <IconButton onClick={handleClear} onMouseDown={handleClear} size="small" sx={{ p: 0, m: 0 }}>
                    <CloseIcon style={{ cursor: 'pointer' }} fontSize="small" />
                  </IconButton>
                )}
                <DateRangeIcon color="secondary" style={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      }
      popperPlacement="bottom-start"
      showMonthDropdown
      useShortMonthInDropdown
      showYearDropdown
    />
  )
}
DataEditContext.DatePickerField = DataEditContextDatePickerField

/**
 * Date resolution field.
 *
 * @param field - Field name.
 * @returns Data context date resolution field.
 * @notExported
 */
const DataEditContextDateResolutionField = <T extends AnyObject>({
  field,
}: {
  field: EditableFieldsOfType<T, string>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, t } = useContext<ContextValue<T>>(Context)

  const handleChange = e => {
    setFields({ [field]: e.target.value })
  }

  const value = flatData[field] as unknown as string

  return (
    <div>
      <RadioGroup value={value} onChange={handleChange}>
        <FormControlLabel
          value="day"
          control={<Radio disabled={disabled[field]} />}
          label={t('dateResolution.fullDate')}
        />
        <FormControlLabel
          value="month"
          control={<Radio disabled={disabled[field]} />}
          label={t('dateResolution.month')}
        />
        <FormControlLabel
          value="year"
          control={<Radio disabled={disabled[field]} />}
          label={t('dateResolution.year')}
        />
      </RadioGroup>
    </div>
  )
}
DataEditContext.DateResolutionField = DataEditContextDateResolutionField

/**
 * Default CV field.
 *
 * @param field - Field name.
 * @returns Data context default CV field.
 * @notExported
 */
const DataEditContextDefaultCvField = <T extends AnyObject>({
  field,
}: {
  field: EditableFieldsOfType<T, boolean>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, t } = useContext<ContextValue<T>>(Context)

  const handleChange = e => {
    setFields({ [field]: e.target.value === 'true' ? true : false })
  }

  const value = flatData[field] as unknown as boolean

  return (
    <div>
      <RadioGroup value={value ? 'true' : 'false'} onChange={handleChange}>
        <Grid container>
          <FormControlLabel value="true" control={<Radio disabled={disabled[field]} />} label={t(`${field}.set`)} />
          <FormControlLabel value="false" control={<Radio disabled={disabled[field]} />} label={t(`${field}.unset`)} />
        </Grid>
      </RadioGroup>
    </div>
  )
}
DataEditContext.DefaultCvField = DataEditContextDefaultCvField

/**
 * Person picker.
 *
 * @param field - Field name.
 * @param dataField - Data field name.
 * @param label - Label.
 * @param rest - Other props.
 * @returns Data context person picker.
 * @notExported
 */
const DataEditContextPersonField = <T extends AnyObject>({
  field,
  dataField,
  label,
  ...rest
}: {
  field: EditableFieldsOfType<T, PersonId | null>
  dataField: EditableFieldsOfType<T, IPerson | null>
  label?: string
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IPerson | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as IPerson
  // const error = errors[field]

  // TODO: add error field
  return (
    <PersonPicker
      onChange={handleChange}
      disabled={disabled[field]}
      value={dataValue ?? null}
      label={label ?? t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.PersonField = DataEditContextPersonField

/**
 * Skill picker.
 *
 * @param field - Field name.
 * @param dataField - Data field name.
 * @param usedSkills - Used skills.
 * @param submitOnEnter - Submit on enter.
 * @param icon - Icon.
 * @param type - Skill type.
 * @param placeholder - Placeholder.
 * @param hideLabel - Hide label.
 * @param rest - Other props.
 * @returns Data context skill picker.
 * @notExported
 */
const DataEditContextSkillField = <T extends AnyObject>({
  field,
  dataField,
  usedSkills,
  submitOnEnter,
  icon,
  type,
  placeholder,
  hideLabel,
  options,
  insetLabel,
  resetOnSelect,
  ...rest
}: {
  field: EditableFieldsOfType<T, SkillId | null>
  dataField: EditableFieldsOfType<T, ISkillOrIndustryOrRole | null>
  usedSkills?
  submitOnEnter?: boolean
  type: string
  icon?: JSX.Element
  placeholder?: string
  hideLabel?: boolean
  options?: ISkillOrIndustryOrRole[]
  insetLabel?: boolean
  resetOnSelect?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t, handleSubmit } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ISkillOrIndustryOrRole | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as ISkillOrIndustryOrRole
  // const error = errors[field]

  // TODO: add error field
  return (
    <SkillPicker
      onChange={handleChange}
      icon={icon}
      disabled={disabled[field]}
      value={dataValue ?? null}
      label={t(`${localeBase}.${field}`)}
      usedSkills={usedSkills}
      onKeyDown={e => submitOnEnter && handleSubmit(e)}
      type={type}
      placeholderText={placeholder}
      placeholderLabel={hideLabel ? placeholder : undefined}
      options={options}
      insetLabel={insetLabel}
      resetOnSelect={resetOnSelect}
      {...rest}
    />
  )
}
DataEditContext.SkillField = DataEditContextSkillField

/**
 * Color picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context color picker.
 * @notExported
 */
const DataEditContextColorField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, string | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: string) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as string
  // const error = errors[field]

  // TODO: add error field
  return <ColorPicker onChange={handleChange} value={dataValue} label={t(`${localeBase}.${field}`)} {...rest} />
}
DataEditContext.ColorField = DataEditContextColorField

/**
 * Organization picker.
 *
 * @param field - Field name.
 * @param dataField - Data field name.
 * @param rest - Other props.
 * @returns Data context organization picker.
 * @notExported
 */
const DataEditContextOrganizationField = <T extends AnyObject>({
  field,
  dataField,
  ...rest
}: {
  field: EditableFieldsOfType<T, OrganizationId | null>
  dataField: EditableFieldsOfType<T, IOrganization | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IOrganization | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as IOrganization
  // const error = errors[field]

  // TODO: add error field
  return (
    <OrganizationPicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue ?? null}
      label={t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.OrganizationField = DataEditContextOrganizationField

/**
 * Employer picker.
 *
 * @param field - Field name.
 * @param dataField - Data field name.
 * @param cvId - CV ID.
 * @param rest - Other props.
 * @returns Data context employer picker.
 * @notExported
 */
const DataEditContextEmployerField = <T extends AnyObject>({
  field,
  dataField,
  cvId,
  ...rest
}: {
  field: EditableFieldsOfType<T, EmployerId | null>
  dataField: EditableFieldsOfType<T, IEmployer | null>
  cvId: CvId
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IEmployer | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as IEmployer
  // const error = errors[field]

  // TODO: add error field
  return (
    <EmployerPicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue ?? null}
      label={t(`${localeBase}.${field}`)}
      cvId={cvId}
      {...rest}
    />
  )
}
DataEditContext.EmployerField = DataEditContextEmployerField

/**
 * Assignment picker.
 *
 * @param field - Field name.
 * @param dataField - Data field name.
 * @param assignmentOptions - Assignment options.
 * @param insetLabel - Is the label inset.
 * @param rest - Other props.
 * @returns Data context assignment picker.
 * @notExported
 */
const DataEditContextAssignmentField = <T extends AnyObject>({
  field,
  dataField,
  assignmentOptions,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, AssignmentId | null>
  dataField: EditableFieldsOfType<T, IAssignment | null>
  assignmentOptions
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IAssignment | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as IAssignment
  // const error = errors[field]

  // TODO: add error field
  return (
    <AssignmentPicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue ?? null}
      label={t(`${localeBase}.${field}`)}
      assignmentOptions={assignmentOptions}
      insetLabel={insetLabel}
      {...rest}
    />
  )
}
DataEditContext.AssignmentField = DataEditContextAssignmentField

/**
 * Language picker.
 *
 * @param field - Field name.
 * @param languageOptions - Language options.
 * @param submitOnEnter - Submit on enter.
 * @param icon - Icon.
 * @param disableClearable - Disable clearable.
 * @param insetLabel - Inset label.
 * @param rest - Other props.
 * @returns Data context language picker.
 * @notExported
 */
const DataEditContextLanguageField = <T extends AnyObject>({
  field,
  languageOptions,
  submitOnEnter,
  icon,
  disableClearable,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, LanguageCode | null>
  languageOptions
  submitOnEnter?: boolean
  icon?: JSX.Element
  disableClearable?: boolean
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t, handleSubmit } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ILanguageSkill | null) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as string

  // const error = errors[field]
  // TODO: add error field
  return (
    <LanguagePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue}
      label={t(`${localeBase}.${field}`)}
      onKeyDown={e => submitOnEnter && handleSubmit(e)}
      languageOptions={languageOptions}
      icon={icon}
      disableClearable={disableClearable}
      insetLabel={insetLabel}
      {...rest}
    />
  )
}
DataEditContext.LanguageField = DataEditContextLanguageField

/**
 * Type picker.
 *
 * @param field - Field name.
 * @param typeOptions - Type options.
 * @param rest - Other props.
 * @returns Data context type picker.
 * @notExported
 */
const DataEditContextTypeField = <T extends AnyObject>({
  field,
  typeOptions,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, TypeCode | null>
  typeOptions
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IAllocation | IWidget | null) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as TypeCode

  // const error = errors[field]
  // TODO: add error field
  return (
    <TypePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue}
      label={t(`${localeBase}.${field}`)}
      typeOptions={typeOptions}
      insetLabel={insetLabel}
      {...rest}
    />
  )
}
DataEditContext.TypeField = DataEditContextTypeField

/**
 * Site picker.
 *
 * @param field - Field name.
 * @param dataField - Data field name.
 * @param rest - Other props.
 * @returns Data context site picker.
 * @notExported
 */
const DataEditContextSiteField = <T extends AnyObject>({
  field,
  dataField,
  ...rest
}: {
  field: EditableFieldsOfType<T, SiteId | null>
  dataField: EditableFieldsOfType<T, ISite | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ISite | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as ISite

  // const error = errors[field]
  // TODO: add error field
  return (
    <SitePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue ?? null}
      label={t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.SiteField = DataEditContextSiteField

/**
 * Role picker.
 *
 * @param field - Field name.
 * @param assignmentRoles - Assignment roles.
 * @param dataField - Data field name.
 * @param showNames - Show names.
 * @param rest - Other props.
 * @param insetLabel - Is the label inset.
 * @returns Data context role picker.
 * @notExported
 */
const DataEditContextRoleField = <T extends AnyObject>({
  field,
  assignmentRoles,
  dataField,
  showNames,
  insetLabel,
  ...rest
}: {
  assignmentRoles
  field: EditableFieldsOfType<T, AssignmentRoleId | null>
  dataField: EditableFieldsOfType<T, IAssignmentRole | null>
  showNames: boolean
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IAssignmentRole | null) => {
    setFields({
      [field]: newValue?.id ?? null,
      [dataField]: newValue,
    })
  }

  const dataValue = flatData[dataField] as unknown as IAssignmentRole

  // const error = errors[field]
  // TODO: add error field
  return (
    <RolePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue ?? null}
      label={t(`${localeBase}.${field}`)}
      assignmentRoles={assignmentRoles}
      showNames={showNames}
      insetLabel={insetLabel}
      {...rest}
    />
  )
}
DataEditContext.RoleField = DataEditContextRoleField

/**
 * State picker.
 *
 * @param field - Field name.
 * @param stateOptions - State options.
 * @param insetLabel - Is the label inset.
 * @returns Data context state picker.
 * @notExported
 */
const DataEditContextStateField = <T extends AnyObject>({
  field,
  stateOptions,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, StateCode | null>
  stateOptions
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IAllocation | null) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as StateCode

  // const error = errors[field]
  // TODO: add error field
  return (
    <StatePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue}
      label={t(`${localeBase}.${field}`)}
      stateOptions={stateOptions}
      insetLabel={insetLabel}
      {...rest}
    />
  )
}
DataEditContext.StateField = DataEditContextStateField

/**
 * Country picker.
 *
 * @param field - Field name.
 * @param countriesOptions - Countries options.
 * @param rest - Other props.
 * @returns Data context country picker.
 * @notExported
 */
const DataEditContextCountryField = <T extends AnyObject>({
  field,
  countriesOptions,
  ...rest
}: {
  field: EditableFieldsOfType<T, CountryCode | string | null>
  countriesOptions
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IPerson | null) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as string

  // const error = errors[field]
  // TODO: add error field
  return (
    <CountryPicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue}
      label={t(`${localeBase}.${field}`)}
      countriesOptions={countriesOptions}
      {...rest}
    />
  )
}
DataEditContext.CountryField = DataEditContextCountryField

/**
 * Checkbox field.
 *
 * @param field - Field name.
 * @param type - Switch or checkbox.
 * @param rest - Other props.
 * @returns Data context checkbox field.
 * @notExported
 */
const DataEditContextCheckField = <T extends AnyObject>({
  field,
  type,
  customLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, boolean>
  type?: string
  customLabel?: string
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, errors, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked

    setFields({ [field]: newValue })
  }

  const value = flatData[field] as unknown as boolean
  const error = errors[field]

  return (
    <>
      <FormControlLabel
        control={
          type === 'switch' ? (
            <Switch onChange={handleChange} checked={value} disabled={disabled[field]} {...rest} />
          ) : (
            <Checkbox
              onChange={handleChange}
              checked={value}
              disabled={disabled[field]}
              style={{ zIndex: 0 }}
              {...rest}
            />
          )
        }
        label={t(customLabel ? `${localeBase}.${customLabel}` : `${localeBase}.${field}`)}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </>
  )
}
DataEditContext.CheckField = DataEditContextCheckField

/**
 * Visibility field.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context visibility field.
 * @notExported
 */
const DataEditContextVisibilityField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, errors, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = 'internal'
    if (e.target.checked) {
      newValue = 'network'
    }
    setFields({ [field]: newValue })
  }

  const value = flatData[field] as unknown as string
  const error = errors[field]

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={value === 'network' ? true : false}
            disabled={disabled[field]}
            {...rest}
          />
        }
        label={t(`${localeBase}.${field}`)}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </>
  )
}
DataEditContext.VisibilityField = DataEditContextVisibilityField

/**
 * Level picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context level picker.
 * @notExported
 */
const DataEditContextSkillLevelField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, ISkillLevel | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, errors, disabled, setFields } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ILevel | null) => {
    setFields({ [field]: newValue as unknown as ISkillLevel })
  }

  const value = flatData[field] as unknown as ILevel
  const error = errors[field]

  return (
    <>
      <LevelPicker kind="skill" value={value} disabled={disabled[field]} onChange={handleChange} {...rest} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </>
  )
}
DataEditContext.SkillLevelField = DataEditContextSkillLevelField

/**
 * Language level picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context language level picker.
 * @notExported
 */
const DataEditContextLanguageLevelField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, ILanguageLevel | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, errors, disabled, setFields } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ILevel | null) => {
    setFields({ [field]: newValue as unknown as ILanguageLevel })
  }

  const value = flatData[field] as unknown as ILevel
  const error = errors[field]

  return (
    <>
      <LevelPicker kind="language" value={value} disabled={disabled[field]} onChange={handleChange} {...rest} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </>
  )
}
DataEditContext.LanguageLevelField = DataEditContextLanguageLevelField

/**
 * Interest level picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context interest level picker.
 * @notExported
 */
const DataEditContextInterestLevelField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, ISkillInterestLevel | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, errors, disabled, setFields } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ISkillInterestLevel | null) => {
    setFields({ [field]: newValue as unknown as ISkillInterestLevel })
  }

  const value = flatData[field] as unknown as ISkillInterestLevel
  const error = errors[field]

  return (
    <>
      <InterestLevelPicker value={value} disabled={disabled[field]} onChange={handleChange} {...rest} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </>
  )
}
DataEditContext.InterestLevelField = DataEditContextInterestLevelField

/**
 * Organization type picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context organization type picker.
 * @notExported
 */
const DataEditContextOrganizationTypeField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, IOrganizationType | null>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: ISearchFields | null) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as IOrganizationType

  // const error = errors[field]
  // TODO: add error field
  return (
    <OrganizationTypePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue}
      label={t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.OrganizationTypeField = DataEditContextOrganizationTypeField

/**
 * Managers picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context managers picker.
 * @notExported
 */
const DataEditContextManagerField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, INetworkManager[]>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: INetworkManager[] | null) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as INetworkManager[]
  // const error = errors[field]
  // TODO: add error field
  return (
    <ManagersPicker
      onChange={handleChange}
      disabled={disabled[field]}
      value={dataValue ?? undefined}
      label={t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.ManagerField = DataEditContextManagerField

/**
 * Contact people picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context contact people picker.
 * @notExported
 */
const DataEditContextContactPeopleField = <T extends AnyObject>({
  field,
  label,
  ...rest
}: {
  field: EditableFieldsOfType<T, IPerson[]>
  label?: string
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IPerson[] | null) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as IPerson[]

  // const error = errors[field]
  // TODO: add error field
  return (
    <ContactPeoplePicker
      onChange={handleChange}
      disabled={disabled[field]}
      value={dataValue ?? null}
      label={label ?? t(`${localeBase}.${field}`)}
      {...rest}
    />
  )
}
DataEditContext.ContactPeopleField = DataEditContextContactPeopleField

/**
 * Network type picker.
 *
 * @param field - Field name.
 * @param typeOptions - Type options.
 * @param rest - Other props.
 * @returns Data context network type picker.
 * @notExported
 */
const DataEditContextNetworkTypeField = <T extends AnyObject>({
  field,
  typeOptions,
  ...rest
}: {
  field: EditableFieldsOfType<T, string | null>
  typeOptions: ITypeOption[]
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: string | null) => {
    setFields({
      [field]: newValue,
    })
  }

  const dataValue = flatData[field] as unknown as TypeCode

  // const error = errors[field]
  // TODO: add error field
  return (
    <NetworkTypePicker
      onChange={handleChange}
      disabled={disabled[field] ?? false}
      value={dataValue}
      label={t(`${localeBase}.${field}`)}
      typeOptions={typeOptions}
      {...rest}
    />
  )
}
DataEditContext.NetworkTypeField = DataEditContextNetworkTypeField

/**
 * Assignment visibility picker.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context assignment visibility picker.
 * @notExported
 */
const DataEditContextAssignmentVisibilityField = <T extends AnyObject>({
  field,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, IAssignmentVisibility[]>
  insetLabel?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: IAssignmentVisibility[] | null) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as IAssignmentVisibility[]
  // const error = errors[field]
  // TODO: add error field
  return (
    <AssignmentVisibilityPicker
      onChange={handleChange}
      disabled={disabled[field]}
      value={dataValue ?? undefined}
      label={t(`${localeBase}.${field}`)}
      insetLabel={insetLabel}
      {...rest}
    />
  )
}
DataEditContext.AssignmentVisibilityField = DataEditContextAssignmentVisibilityField

/**
 * Network picker.
 *
 * @param field - Field name.
 * @param icon - Icon.
 * @param options - Options.
 * @param rest - Other props.
 * @returns Data context network picker.
 * @notExported
 */
const DataEditContextNetworkField = <T extends AnyObject>({
  field,
  icon,
  options,
  ...rest
}: {
  multiple: true
  field: EditableFieldsOfType<T, INetwork[]>
  icon?: JSX.Element
  options?: INetwork[]
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: INetwork[]) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as INetwork[]
  // const error = errors[field]
  // TODO: add error field
  return (
    <NetworkPicker
      onChange={handleChange}
      disabled={disabled[field]}
      values={dataValue ?? undefined}
      label={t(`${localeBase}.${field}`)}
      icon={icon}
      items={options}
      {...rest}
    />
  )
}
DataEditContext.NetworkField = DataEditContextNetworkField

/**
 * Markdown editor.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context markdown editor.
 * @notExported
 */
const DataEditContextMarkdownField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, setFields } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: string | undefined) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as string
  // const error = errors[field]
  // TODO: add error field
  return <MDEditor onChange={handleChange} value={dataValue} {...rest} />
}
DataEditContext.MarkdownField = DataEditContextMarkdownField

const DataEditContextRTEField = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: string | undefined) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as string

  return (
    <SlateEditor
      onChange={handleChange}
      value={dataValue ?? ''}
      label={t(`${localeBase}.${field}`)}
      disabled={disabled[field]}
      {...rest}
    />
  )
}
DataEditContext.RTEField = DataEditContextRTEField

/**
 * Rich text editor.
 *
 * @param field - Field name.
 * @param rest - Other props.
 * @returns Data context rich text editor.
 * @notExported
 */
const DataEditContextRichTextEditor = <T extends AnyObject>({
  field,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (newValue: string) => {
    setFields({
      [field]: newValue ?? null,
    })
  }

  const dataValue = flatData[field] as unknown as string

  return (
    <RichTextEditor
      onChange={handleChange}
      value={dataValue ?? ''}
      label={t(`${localeBase}.${field}`)}
      disabled={disabled[field]}
      {...rest}
    />
  )
}
DataEditContext.RichTextEditor = DataEditContextRichTextEditor

/**
 * Language selector.
 *
 * @param field - Field name.
 * @param required - Is field required.
 * @param rest - Other props.
 * @returns Data context language selector.
 * @notExported
 */
const DataEditContextLanguageSelect = <T extends AnyObject>({
  field,
  required,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
  required?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (e: SelectChangeEvent<string>) => {
    setFields({
      [field]: e.target.value,
    })
  }

  const dataValue = flatData[field] as unknown as string

  return (
    <FormControl sx={{ mt: 1 }} fullWidth {...rest}>
      <>
        {!insetLabel ? (
          <CaleoInputLabel required={required ?? false} label={t(`${localeBase}.${field}`)} />
        ) : (
          <InputLabel id="languageSelectLabel" shrink>
            <>
              {t(`${localeBase}.${field}`)}
              <span style={{ color: '#BE3C3C', fontSize: '0.85em', marginLeft: '5px' }}>
                {required && `${t('required')}`}
              </span>
            </>
          </InputLabel>
        )}
        <Select
          id={field}
          notched={insetLabel ? true : undefined}
          labelId={insetLabel ? 'languageSelectLabel' : undefined}
          onChange={handleChange}
          value={dataValue}
          size="small"
          label={
            insetLabel ? (
              <>
                {t(`${localeBase}.${field}`)}
                <span style={{ color: '#BE3C3C', fontSize: '0.85em', marginLeft: '5px' }}>
                  {required && `${t('required')}`}
                </span>
              </>
            ) : undefined
          }
          fullWidth
        >
          <MenuItem value={'fi'}>{t('languages.fi')}</MenuItem>
          <MenuItem value={'en'}>{t('languages.en')}</MenuItem>
        </Select>
      </>
    </FormControl>
  )
}
DataEditContext.LanguageSelect = DataEditContextLanguageSelect

const DataEditContextPhoneInput = <T extends AnyObject>({
  field,
  required,
  insetLabel,
  ...rest
}: {
  field: EditableFieldsOfType<T, string>
  insetLabel?: boolean
  required?: boolean
} & Omit<Record<string, unknown>, 'onChange' | 'value' | 'error' | 'name'>) => {
  const { flatData, disabled, setFields, localeBase, t } = useContext<ContextValue<T>>(Context)

  const handleChange = (value: string) => {
    setFields({
      [field]: value,
    })
  }

  const dataValue = flatData[field] as unknown as string

  return (
    <PhoneNumberInput
      required={required}
      insetLabel={insetLabel}
      label={t(`${localeBase}.${field}`)}
      onChange={handleChange}
      value={dataValue}
      disabled={disabled[field]}
      {...rest}
    />
  )
}

DataEditContext.PhoneInput = DataEditContextPhoneInput

export default DataEditContext
