import React from 'react'
import {
  BtnBold,
  BtnItalic,
  createButton,
  Editor,
  EditorProvider,
  Toolbar,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  BtnBulletList,
  BtnClearFormatting,
} from 'react-simple-wysiwyg'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'

const BtnAlignLeft = createButton('Align center', <FormatAlignLeftIcon sx={{ fontSize: 15 }} />, 'justifyLeft')
const BtnAlignCenter = createButton('Align center', <FormatAlignCenterIcon sx={{ fontSize: 15 }} />, 'justifyCenter')
const BtnAlignRight = createButton('Align center', <FormatAlignRightIcon sx={{ fontSize: 15 }} />, 'justifyRight')

export default function CustomEditor({ value, onChange, ...rest }) {
  return (
    <EditorProvider>
      <Editor value={value} onChange={onChange} {...rest}>
        <Toolbar>
          <BtnUndo />
          <BtnRedo />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikeThrough />
          <Separator />
          <BtnNumberedList />
          <BtnBulletList />
          <Separator />
          <BtnLink />
          <BtnClearFormatting />
          <HtmlButton />
          <Separator />
          <BtnStyles />
          <Separator />
          <BtnAlignLeft />
          <BtnAlignCenter />
          <BtnAlignRight />
        </Toolbar>
      </Editor>
    </EditorProvider>
  )
}
