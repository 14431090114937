import { Box, Button, Grid, Link, Popover, Slider, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchLanguage } from 'types/searchInterfaces'
import { languageLevels } from 'utils/utils'
import colors from 'constants/colors'
import { ILanguageLevel } from 'types/cvsInterfaces'

/** @notExported */
interface ILanguageEditPopoverProps {
  /** The item to edit. */
  item: ISearchLanguage
  /** The anchor element. */
  anchorEl: HTMLElement | undefined
  /** The updated item. */
  updatedItem: ISearchLanguage
  /** Function to set the updated item. */
  setUpdatedItem: (updatedItem: ISearchLanguage) => void
  /** Function to save the updated item. */
  saveItem: (updatedItem: ISearchLanguage) => void
  /** Function to close the popover. */
  onClose: () => void
}

/**
 * EditPopover component for editing items in a popover.
 *
 * @param {ILanguageEditPopoverProps} item - The item to be edited
 * @param {HTMLElement | undefined} anchorEl - The anchor element for the popover
 * @param {ISearchLanguage} updatedItem - The updated item
 * @param {React.Dispatch<React.SetStateAction<ISearchLanguage>>} setUpdatedItem - Function to set the updated item
 * @param {Function} saveItem - Function to save the item
 * @param {Function} onClose - Function to close the popover
 */
const LanguageEditPopover: React.FC<ILanguageEditPopoverProps> = ({
  item,
  anchorEl,
  updatedItem,
  setUpdatedItem,
  saveItem,
  onClose,
}) => {
  const { t } = useTranslation()
  const levels = languageLevels()

  return (
    <Popover
      sx={{
        maxWidth: '1000px',
        '.MuiPopover-paper': {
          borderRadius: 1,
        },
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {t('search.language.criteria')}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {item.level !== updatedItem.level && (
              <Link
                underline="hover"
                sx={{
                  color: colors.newPrimary,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => setUpdatedItem(item)}
              >
                {t('search.reset')}
              </Link>
            )}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Grid container item xs={12} justifyContent="space-between">
                <Typography variant="body2">{t('search.level')}</Typography>
                <Typography variant="body2">{`${updatedItem.level ?? 0}: ${
                  updatedItem.level
                    ? levels.find(levelValue => levelValue.level === updatedItem.level)?.label
                    : t('search.unspecified')
                }`}</Typography>
              </Grid>
              <Slider
                defaultValue={updatedItem.level ?? 0}
                value={updatedItem.level ?? 0}
                step={1}
                marks
                min={0}
                max={5}
                onChange={(_e, value) => {
                  if (typeof value === 'number') {
                    setUpdatedItem({ ...updatedItem, level: value as ILanguageLevel })
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button color="newPrimary" variant="contained" onClick={() => saveItem(updatedItem)} sx={{ mr: 1 }}>
              {t('search.save')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default LanguageEditPopover
