import { SubAPI } from './API'
import { IUserManualPage } from '../types/userManualInterfaces'

/**
 * User manual API
 * @notExported
 */
class UserManualAPI extends SubAPI {
  /**
   * Get user manual pages.
   *
   * @returns Array of user manual pages.
   */
  public getUserManualPages(controller?: AbortController): Promise<IUserManualPage[]> {
    return this.api.get(`articles`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get user manual page.
   *
   * @param id - User manual page ID.
   * @returns User manual page.
   */
  public getUserManualPage(id: number, controller?: AbortController): Promise<IUserManualPage> {
    return this.api.get(`articles/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get user manual page by url.
   *
   * @param url - User manual page URL.
   * @returns User manual page.
   */
  public getUserManualPageByUrl(url: string, controller?: AbortController): Promise<IUserManualPage> {
    return this.api.get(
      `articles/url?url=${encodeURIComponent(url)}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Create user manual page.
   *
   * @param data - User manual page data.
   * @returns Created user manual page.
   */
  public postUserManualPage(data: IUserManualPage, controller?: AbortController): Promise<IUserManualPage> {
    return this.api.post(`articles`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update user manual page.
   *
   * @param pageId - User manual page ID.
   * @param data - User manual page data.
   * @returns Updated user manual page.
   */
  public putUserManualPage(id: number, data: IUserManualPage, controller?: AbortController): Promise<IUserManualPage> {
    return this.api.put(`articles/${id}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete user manual page.
   *
   * @param pageId - User manual page ID.
   * @returns Promise of page deletion.
   */
  public deleteUserManualPage(id: number, controller?: AbortController): Promise<void> {
    return this.api.delete(`articles/${id}`, controller ? { signal: controller.signal } : undefined)
  }
}

export const userManualAPI = new UserManualAPI()
