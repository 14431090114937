import React from 'react'
import { Fab, Tooltip } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface HelpButtonProps {
  /* Action to perform on click */
  onChange: (open: boolean) => void
}

/**
 * Edit button component.
 *
 * @returns Edit button.
 * @notExported
 * @category Buttons
 */
const HelpButton: React.FC<HelpButtonProps> = ({ onChange }) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('help')}>
      <Fab
        color="primary"
        aria-label={t('help')}
        size="medium"
        onClick={() => {
          onChange(true)
        }}
        sx={{ position: 'fixed', bottom: 50, right: 50 }}
      >
        <HelpIcon />
      </Fab>
    </Tooltip>
  )
}

export default HelpButton
