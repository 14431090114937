import { Box, Button, Grid, Typography } from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { allocationAPI } from 'api/AllocationAPI'
import { organizationAPI } from 'api/OrganizationAPI'
import { personAPI } from 'api/PersonAPI'
import { teamAPI } from 'api/TeamAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { getAllocationInitialData } from 'Components/reusable/DataContext/InitialData'
import { ownAllocationSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { useUser } from 'hooks'
import { useAllocations, useSupplierAllocations } from 'hooks/allocations'
import { useCompanyPermissions, useTeamLeaderPermissions } from 'hooks/permissions'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocation, IAllocationScopeItem } from 'types/allocationInterfaces'
import { IError } from 'types/error'
import { ITeam } from 'types/teamInterfaces'
import { IOrganization, IPerson } from 'types/userInterfaces'
import AllocationCalendar from './components/AllocationCalendar/AllocationCalendar'
import AllocationModal from './components/AllocationModal'
import AllocationTable from 'Components/General/AllocationTable/AllocationTable'
import PersonAllocationView from './PersonAllocationView/PersonAllocationView'
import AllocationOverview from './AllocationOverview'
import { useIsComponentMounted } from 'hooks/util'
import { TabItem } from 'pages/Search/SearchComponents/TabItem'
import AddIcon from '@mui/icons-material/Add'
import colors from 'constants/colors'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SupplierAllocations from './SupplierAllocations'
import { useHelpButton } from 'Components/General/HelpButtonProvider'

/**
 * Allocation component.
 *
 * @returns Allocation component.
 * @notExported
 */
const Allocation: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const { user, features, groups } = useUser()
  const { t } = useTranslation()
  const { profileId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { setUrl } = useHelpButton()

  const personId = profileId ?? `${user?.Person?.id}`

  const [editedItem, setEditedItem] = useState<IAllocation | null | undefined>(undefined)
  const [person, setPerson] = useState<IPerson>()
  const [backendError, setBackendError] = useState<IError>()
  const [tabValue, setTabValue] = useState<number>()
  const [teams, setTeams] = useState<ITeam[]>([])
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [permissions, setPermissions] = useState<boolean>(false)
  const [updateFromEditing, setUpdateFromEditing] = useState<Date>()
  const { salesAccess } = useCompanyPermissions(person?.organizationId)
  const { allocations: ownAllocations, setUpdate } = useAllocations(parseInt(personId, 10))
  const { teamLeaderAccess } = useTeamLeaderPermissions()
  const { allocations: supplierAllocations } = useSupplierAllocations(salesAccess)

  useEffect(() => {
    setUrl('/allocation')
  }, [])

  useEffect(() => {
    if (features && groups && ownAllocations) {
      if (
        (salesAccess && (features.includes('allocationOverview') || groups.includes('admin'))) ||
        (teamLeaderAccess && features.includes('allocationOverview'))
      ) {
        setPermissions(true)
        if (!tabValue) {
          setTabValue(0)
        }
      } else {
        setPermissions(false)
        if (!tabValue) {
          setTabValue(1)
        }
      }
    }
  }, [salesAccess, features, groups, ownAllocations, teamLeaderAccess])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (personId) {
        try {
          const person = await personAPI.getPersonData(parseInt(personId, 10), controller)
          if (!isComponentMounted.current) return
          setPerson(person)
        } catch (error) {
          setBackendError(error as IError)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [personId])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (permissions) {
          const results = await teamAPI.getSalesOrganizationsTeams(controller)
          if (!isComponentMounted.current) return
          setTeams(results)
        }
        if (user) {
          const results = await organizationAPI.getSalesAccessOrganizations(controller)
          if (!isComponentMounted.current) return
          setOrganizations(results)
        }
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [permissions])

  useEffect(() => {
    if (location.pathname.includes('/suppliers') && supplierAllocations && supplierAllocations.length > 0) {
      setTabValue(2)
    }
  }, [supplierAllocations])

  const deleteItem = async (item: IAllocation) => {
    try {
      await allocationAPI.delete(item.id)
      if (!isComponentMounted.current) return
      setUpdate(true)
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  const getOptions = () => {
    const options: IAllocationScopeItem[] = []
    if (teams && teams.length > 0) {
      options.push(
        ...teams.map(team => ({
          id: team.id,
          label: '',
          type: 'team',
          translations: team.translations,
        }))
      )
    }
    if (salesAccess && organizations && organizations.length > 0) {
      options.push(
        ...organizations.map(organization => ({
          id: organization.id,
          label: '',
          type: 'company',
          translations: organization.translations,
        }))
      )
    }
    return options
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const handleChange = (_event: unknown, newValue: number) => {
    setTabValue(newValue)
    if (newValue === 2) {
      navigate('/allocation/suppliers')
    } else {
      navigate('/allocation')
    }
  }

  if (profileId && ownAllocations) {
    return <PersonAllocationView collapsible={true} />
  }

  const header = useMemo(() => {
    if (typeof tabValue === 'number') {
      switch (tabValue) {
        case 0:
          return t('allocation.title')
        case 1:
          return t('allocation.ownAllocations')
        case 2:
          return t('allocation.supplierAllocations')
      }
    }
  }, [tabValue])

  return (
    <Box
      px={2}
      pb={2}
      sx={{
        maxWidth: 1200,
        backgroundColor: colors.searchBackground,
        margin: '0 auto',
        backgroundImage: 'none',
      }}
    >
      {permissions && (
        <Grid container spacing={0.5} mb={5}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="newPrimary"
            textColor="inherit"
            sx={{
              [`& .${tabsClasses.indicator}`]: {
                display: 'none',
              },
            }}
          >
            <TabItem label={t('allocation.title')} />
            <TabItem label={t('allocation.ownAllocations')} />
            {salesAccess && supplierAllocations.length > 0 && <TabItem label={t('allocation.supplierAllocations')} />}
          </Tabs>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }} fontWeight="bold">
              {header}
            </Typography>
          </Grid>
          {tabValue !== 2 && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setEditedItem(null)
                }}
                startIcon={<AddIcon />}
              >
                <span style={{ fontWeight: 'bold' }}> {t('allocation.addTitle')}</span>
              </Button>
            </Grid>
          )}
        </Grid>
        {permissions && tabValue === 0 && (
          <AllocationOverview
            setEditedItem={setEditedItem}
            deleteItem={deleteItem}
            getOptions={getOptions}
            salesAccess={salesAccess}
            updateFromEditing={updateFromEditing}
          />
        )}
        {tabValue === 1 && (
          <>
            <AllocationCalendar items={ownAllocations} />
            {user?.Person?.id && (
              <AllocationTable
                items={ownAllocations}
                openItem={(item: IAllocation) => {
                  setEditedItem(item)
                }}
                deleteItem={deleteItem}
                personId={user.Person.id}
                salesAccess={salesAccess}
              />
            )}
          </>
        )}
        {tabValue === 2 && salesAccess && <SupplierAllocations items={supplierAllocations} />}
      </Grid>
      {editedItem !== undefined && (
        <AllocationModal
          item={editedItem}
          initialData={getAllocationInitialData(user?.Person?.id === parseInt(personId, 10))}
          schema={ownAllocationSchema()}
          maxWidth="sm"
          fullWidth={true}
          localeBase={'allocation'}
          api={allocationAPI}
          submitOnModal={true}
          onClose={({ newItem }) => {
            setEditedItem(undefined)
            if (newItem && tabValue === 1) {
              setUpdate(true)
            } else if (newItem) {
              setUpdateFromEditing(new Date())
            }
          }}
          header="allocation"
          personId={parseInt(personId, 10)}
          management={tabValue === 0 && permissions}
          scopeOptions={getOptions() || []}
          submitColor="primary"
          noEscClose
        />
      )}
    </Box>
  )
}

export default Allocation
