import { Grid, useTheme } from '@mui/material'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React, { ComponentProps } from 'react'
import Editor from 'react-simple-wysiwyg'
import CustomEditor from './CustomEditor'

interface OwnProps {
  value: string
  onChange: (newData: string) => void
  error?: string
  required?: boolean
  label: string
  disabled?: boolean
}

const RichTextEditor: React.FC<OwnProps & Omit<ComponentProps<typeof Editor>, keyof OwnProps>> = ({
  value,
  onChange,
  error,
  required,
  label,
  disabled = false,
  ...rest
}) => {
  const theme = useTheme()

  function change(e) {
    onChange(e.target.value)
  }

  return (
    <Grid>
      <CaleoInputLabel required={required} label={label} />
      <CustomEditor
        value={value}
        onChange={change}
        disabled={disabled}
        containerProps={{ style: { borderRadius: 0, marginTop: '0.3rem', marginBottom: '0.3rem' } }}
        {...rest}
      />
      {!!error && (
        <Grid item xs={12}>
          <span style={{ color: theme.palette.error.main, fontSize: '0.75rem' }}>{error}</span>
        </Grid>
      )}
    </Grid>
  )
}

export default RichTextEditor
