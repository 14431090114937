import { Box, Grid, Typography } from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import React, { useEffect, useState } from 'react'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import { useTranslation } from 'react-i18next'
import QuickSearch from './QuickSearch'
import DetailedSearch from './DetailedSearch'
import { IError } from 'types/error'
import { searchEmployeesApi } from 'api/SearchEmployeesAPI'
import { useIsComponentMounted } from 'hooks/util'
import { ISearchFields, ISearchToken } from 'types/searchInterfaces'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { skillAPI } from 'api/SkillAPI'
import { IIndustry, IRole, ISkill, ISkillOrIndustryOrRole } from 'types/cvsInterfaces'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import Results from './Results'
import { TabItem } from './SearchComponents/TabItem'
import colors from 'constants/colors'
import { useHelpButton } from 'Components/General/HelpButtonProvider'

/**
 * Converts the given data object into an array of search tokens.
 *
 * @param {ISearchFields} data - The data object to convert.
 * @return {ISearchToken[]} An array of search tokens.
 */
export const convertToTokens = (data: ISearchFields): ISearchToken[] => {
  const tokens: ISearchToken[] = []

  if (data.availability.startDate) {
    const token = {
      kind: 'availability',
      required: true,
      start: data.availability.startDate,
      end: data.availability.endDate,
    }

    if (!token.end) {
      token.end = new Date(token.start)
      token.end.setDate(token.end.getDate() + 90)
    }

    tokens.push(token)
  }

  if (data.certificates) {
    for (const cert of data.certificates) {
      if (cert.certificate) {
        tokens.push({
          kind: 'certificateText',
          text: cert.certificate,
        })
      }
    }
  }

  if (data.degrees) {
    for (const degree of data.degrees) {
      if (degree.degree) {
        tokens.push({
          kind: 'degreeText',
          text: degree.degree,
        })
      }
    }
  }

  if (data.courses) {
    for (const course of data.courses) {
      if (course.course) {
        tokens.push({
          kind: 'courseText',
          text: course.course,
        })
      }
    }
  }

  if (data.location) {
    tokens.push({
      kind: 'locationText',
      text: data.location,
    })
  }

  if (data.projectCountItems) {
    for (const project of data.projectCountItems) {
      const skillIds = project.skills.map(skill => skill.id)
      tokens.push({
        kind: 'projectCount',
        count: project.count,
        skills: skillIds,
      })
    }
  }

  if (data.name) {
    for (let part of data.name.replace(/,/g, ' ').split(/\s+/)) {
      part = part.trim()
      if (part.length > 0) {
        tokens.push({
          kind: 'name',
          value: part,
        })
      }
    }
  }

  if (data.roles) {
    for (const role of data.roles) {
      if (role.skillId) {
        tokens.push({
          kind: 'role',
          skillId: role.skillId,
          minExperience: role.experienceMonths ?? 0,
          minLevel: role.level ?? 0,
          required: role.required,
        })
      }
    }
  }

  if (data.skills) {
    for (const skill of data.skills) {
      if (skill.skillId) {
        tokens.push({
          kind: 'skill',
          skillId: skill.skillId,
          minExperience: skill.experienceMonths ?? 0,
          minLevel: skill.level ?? 0,
          required: skill.required,
        })
      }
    }
  }

  if (data.industries) {
    for (const industry of data.industries) {
      if (industry.skillId) {
        tokens.push({
          kind: 'industry',
          skillId: industry.skillId,
          minExperience: industry.experienceMonths ?? 0,
          minLevel: industry.level ?? 0,
          required: industry.required,
        })
      }
    }
  }

  if (data.languages) {
    for (const language of data.languages) {
      if (language.languageCode) {
        tokens.push({
          kind: 'language',
          languageCode: language.languageCode,
          level: language.level,
        })
      }
    }
  }

  return tokens
}

/**
 * Builds an array of network IDs from the given search fields.
 *
 * @param {ISearchFields} data - The search fields containing the networks.
 * @return {number[]} An array of network IDs.
 * @notExported
 */
const buildNetworkIds = (data: ISearchFields) => {
  return data.networks.map(network => network.id)
}

/**
 * Renders the search component with quick and detailed search options.
 *
 * @return {JSX.Element} The rendered search component.
 */
const Search: React.FC = () => {
  const { t } = useTranslation()
  const isComponentMounted = useIsComponentMounted()
  const { searchData, setSearchData } = useSearchData()
  const { setUrl } = useHelpButton()

  const [loading, setLoading] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()
  const [tab, setTab] = useState<number>(0)
  const [items, setItems] = useState<ISkillOrIndustryOrRole[]>([])
  const [skills, setSkills] = useState<ISkill[]>([])
  const [industries, setIndustries] = useState<IIndustry[]>([])
  const [roles, setRoles] = useState<IRole[]>([])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setUrl('/employees')

        const data = await skillAPI.getAll(controller)
        if (!isComponentMounted.current) return
        setItems(data)
        const skillItems: ISkill[] = []
        const roleItems: IRole[] = []
        const industryItems: IIndustry[] = []
        for (const item of data) {
          if (item.kind === 'skill') {
            skillItems.push(item)
          } else if (item.kind === 'role') {
            roleItems.push(item)
          } else if (item.kind === 'industry') {
            industryItems.push(item)
          }
        }

        setSkills(skillItems)
        setRoles(roleItems)
        setIndustries(industryItems)
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  const onSubmit = async () => {
    const { terms } = searchData

    try {
      setLoading(true)
      const tokens = convertToTokens(terms)
      const networkIds = buildNetworkIds(terms)

      const options = {
        networkIds,
        tokens,
      }

      const results = await searchEmployeesApi.search({ ...options, partial: true })

      if (!isComponentMounted.current) return
      setSearchData({
        terms: terms,
        results: results,
        lastFetched: new Date(),
        ownOrganization: searchData.terms.networks.length === 1 && searchData.terms.networks[0].autoGenerated,
      })
    } catch (err) {
      setBackendError(err as IError)
    } finally {
      setLoading(false)
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <Box
      px={2}
      pb={2}
      sx={{
        maxWidth: 1000,
        backgroundColor: colors.searchBackground,
        margin: '0 auto',
        backgroundImage: 'none',
      }}
    >
      <Grid container spacing={0.5} mb={5}>
        <Tabs
          value={tab}
          onChange={(_e, value) => setTab(value)}
          indicatorColor="newPrimary"
          textColor="inherit"
          sx={{
            [`& .${tabsClasses.indicator}`]: {
              display: 'none',
            },
          }}
        >
          <TabItem label={t('search.quickSearch')} />
          <TabItem label={t('search.detailedSearch')} />
        </Tabs>
      </Grid>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textTransform: 'none' }} fontWeight="bold">
        {tab === 0 ? t('search.quickSearch') : t('search.detailedSearch')}
      </Typography>
      {tab === 0 && <QuickSearch onSubmit={onSubmit} loading={loading} options={items} />}
      {tab === 1 && (
        <DetailedSearch
          onSubmit={onSubmit}
          loading={loading}
          skillOptions={skills}
          roleOptions={roles}
          industryOptions={industries}
        />
      )}
      {loading && <LoadingIndicator />}
      {searchData.results && !loading && <Results />}
    </Box>
  )
}

export default Search
